import React from 'react';
import { Navigate } from 'react-router';
import { Route } from 'react-router';
import { Routes } from 'react-router';

import ContentFilter from './ContentFilterList';
import DashboardIndex from './DashboardIndex';
import EngagementDashboard from './EnagementDashboard';

export default function DashboardRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<EngagementDashboard />} />
        <Route path="overview" element={<DashboardIndex />} />
        <Route path="content-filter" element={<ContentFilter />} />
        <Route path="engagement" element={<EngagementDashboard />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </div>
  );
}
