import { gql } from '@apollo/client';

import AffiliateReportFragment from './AffiliateReportFragment';

export default gql`
  query ($id: ID!) {
    report(id: $id) {
      ...AffiliateReport
    }
  }
  ${AffiliateReportFragment}
`;
