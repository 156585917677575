import { gql } from '@apollo/client';

import AffiliateEventSummaryFragment from './AffiliateEventSummaryFragment';

export default gql`
  fragment AffiliateSummary on AffiliateSummary {
    purchase {
      ...AffiliateEventSummary
    }
    club_join {
      ...AffiliateEventSummary
    }
    membership {
      ...AffiliateEventSummary
    }
    page {
      ...AffiliateEventSummary
    }
  }
  ${AffiliateEventSummaryFragment}
`;
