import Box from '@mui/material/Box';
import React from 'react';

export default function ErrorBox({ error, ...props }) {
  return (
    <Box
      sx={{ bgcolor: 'error.main', color: 'error.contrastText', p: 2 }}
      {...props}
    >
      {error.message ? error.message : error}
    </Box>
  );
}
