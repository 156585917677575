import styled from '@emotion/styled';
import { palette } from '@mui/system';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';

import A from '../../components/atoms/A';

function Link(props) {
  const { variant } = props;

  switch (variant) {
    case 'nav':
      return <ReactRouterNavLink {...props} />;
    case 'external':
      return <A {...props} />;
    default:
      return <ReactRouterLink {...props} />;
  }
}

export default styled(Link)`
  text-decoration: inherit;
  ${palette}
`;
