import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import Button from '../../components/atoms/form/Button';

export default function PayoutLogin({ account }) {
  return (
    <Box sx={{ maxWidth: 600 }}>
      <Typography variant="h3" sx={{ mb: 4 }}>
        Fable Payouts
      </Typography>

      <Typography variant="body" sx={{ mb: 4 }} component="p">
        Your bank account is connected ✅
      </Typography>

      <Box>
        <Button href={account.login_link.url} target="_blank">
          Go to Stripe Dashboard
        </Button>
      </Box>
    </Box>
  );
}
