export default function onSubmitHelper(onSubmit) {
  return async (values, formik) => {
    formik.setStatus(null);
    try {
      await onSubmit(values, formik);
    } catch (e) {
      console.log('Form submit error', e);
      formik.setStatus(e);
    }
  };
}
