import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import clsx from 'clsx';
import { Form } from 'formik';
import { Formik } from 'formik';
import React from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router';
import * as Yup from 'yup';

import A from '../components/atoms/A';
import Logo from '../components/atoms/Logo';
import FormError from '../components/atoms/form/FormError';
import FormRow from '../components/atoms/form/FormRow';
import PasswordField from '../components/molecules/form/PasswordField';
import { SubmitButton } from '../components/molecules/form/SubmitButton';
import TextField from '../components/molecules/form/TextField';
import { useAuth } from '../hooks/useAuth';
import { externalProviders } from '../hooks/useAuth';
import firebase from '../services/firebase';
import { handleToken } from '../state/apolloClient';
import { COLORS } from '../utils/constants';
import { FONT_FAMILIES } from '../utils/constants';
import { FORM_ERRORS } from '../utils/constants';
import { FORM_PLACEHOLDERS } from '../utils/constants';
import { STORAGE_KEYS } from '../utils/constants';
import { URLS } from '../utils/constants';
import onSubmitHelper from '../utils/onSubmitHelper';

const PREFIX = 'SignInPage';

const classes = {
  root: `${PREFIX}-root`,
  welcome: `${PREFIX}-welcome`,
  textField: `${PREFIX}-textField`,
  emailField: `${PREFIX}-emailField`,
  submitButton: `${PREFIX}-submitButton`,
  emailButton: `${PREFIX}-emailButton`,
  googleButton: `${PREFIX}-googleButton`,
  appleButton: `${PREFIX}-appleButton`,
  agreement: `${PREFIX}-agreement`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
    marginTop: '142px',
    maxWidth: '400px',
    fontFamily: FONT_FAMILIES.sf_ui_display_regular,
    '& h3': {
      fontFamily: FONT_FAMILIES.noe_display_bold,
      fontSize: '28px',
      lineHeight: '28px',
      marginTop: '5px',
    },
  },

  [`& .${classes.welcome}`]: {
    color: theme.palette.fable.grayHurricane,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.03em',
    marginTop: '25.33px',
  },

  [`& .${classes.textField}`]: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${theme.palette.fable.grayMedium}`,
      borderRadius: '52px',
      fontSize: '15px',
      letterSpacing: '0.3px',
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      border: `1px solid ${theme.palette.fable.mahogany}`,
    },
    '& .MuiOutlinedInput-root:hover': {
      boxShadow: `0 4px 14px ${theme.palette.fable.graySmoke}`,
    },
    '& .MuiOutlinedInput-root > fieldset': {
      border: '1px solid transparent',
    },
    '& .MuiOutlinedInput-root:hover > fieldset': {
      border: '1px solid transparent',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid transparent',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: '1px solid transparent',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '21px',
      paddingBottom: '8px',
      paddingLeft: '25px',
      paddingRight: '25px',
      height: 'auto',
      color: theme.palette.fable.grayHurricane,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.fable.grayStrong,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.fable.grayStrong,
    },
    '& .MuiInputLabel-root.Mui-focused.Mui-error': {
      color: theme.palette.fable.mahogany,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: `translate(0, ${theme.spacing(1)})`,
      fontSize: '12px',
      left: '25px',
      top: '3px',
    },
    '& .MuiOutlinedInput-notchedOutline > legend': {
      display: 'none',
    },
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },

  [`& .${classes.emailField}`]: {
    marginBottom: '11px',
  },

  [`& .${classes.submitButton}`]: {
    borderRadius: '52px',
    width: '100%',
    lineHeight: '24px',
    textTransform: 'none',
    fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
    fontSize: '16px',
    '& img': {
      marginRight: '6px',
      position: 'relative',
      top: '-1px',
    },
  },

  [`& .${classes.emailButton}`]: {
    color: theme.palette.fable.whiteFang,
    backgroundColor: theme.palette.fable.blackSwan,
    marginTop: theme.spacing(2),
    padding: '15px',
    '&:hover': {
      borderColor: '#332e32',
      background: '#332e32',
    },
  },

  [`& .${classes.googleButton}`]: {
    border: `1px solid ${theme.palette.fable.blackSwan}`,
    color: theme.palette.fable.blackSwan,
    marginTop: '27px',
    padding: '13px',
  },

  [`& .${classes.appleButton}`]: {
    border: `1px solid ${theme.palette.fable.blackSwan}`,
    color: theme.palette.fable.blackSwan,
    marginTop: '-4px',
    padding: '13px',
  },

  [`& .${classes.agreement}`]: {
    color: theme.palette.fable.grayStrong,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.03em',
    paddingTop: '2px',
  },
}));

const EmailPasswordSchema = Yup.object().shape({
  password: Yup.string().required(FORM_ERRORS.required_password),
  email: Yup.string()
    .email(FORM_ERRORS.invalid_email)
    .required(FORM_ERRORS.required_email),
});

const renderRedirect = (path) => {
  return <Navigate to={path ? path : '/'} />;
};

const RenderProvider = ({ providerName, classNames, redirectPath }) => {
  const location = useLocation();
  const auth = useAuth();

  const onSignIn = (provider) => {
    if (location.pathname !== redirectPath)
      localStorage.setItem(STORAGE_KEYS.auth_redirect_path, redirectPath);
    auth.signInWithProvider(provider.login);
  };

  const externalProvider = externalProviders.find(
    (provider) => provider.name === providerName
  );
  return (
    <SubmitButton
      key={externalProvider.name}
      className={classNames}
      onClick={() => onSignIn(externalProvider)}
    >
      <img src={externalProvider.icon} alt={`${externalProvider.name} Icon`} />
      Continue with {externalProvider.name}
    </SubmitButton>
  );
};

const RenderErrors = ({ status, errors, touched }) => {
  let formErrors = [];
  if (status) {
    formErrors.push(status);
  }
  Object.keys(errors).forEach((errorKey) => {
    Object.keys(touched).forEach((touchedKey) => {
      if (errorKey === touchedKey) {
        formErrors.push(errors[errorKey]);
      }
    });
  });
  if (
    formErrors.indexOf(FORM_ERRORS.required_email) > -1 &&
    formErrors.indexOf(FORM_ERRORS.required_password) > -1
  ) {
    formErrors = [FORM_ERRORS.required_email_and_password];
  }
  let errorItems = [];
  formErrors.forEach((error) => {
    errorItems.push(
      <FormError
        key={error}
        error={error}
        borderRadius={4}
        sx={{ bgcolor: 'transparent', color: COLORS.mahogany }}
        fontFamily=""
        fontSize="12px"
        lineHeight="16px"
        letterSpacing="0.36px"
      />
    );
  });
  return <FormRow>{errorItems}</FormRow>;
};

export default function SignInPage() {
  const theme = useTheme();
  const auth = useAuth();

  if (auth.authUser !== null && (auth.isAffiliate || auth.isStaff))
    return renderRedirect();

  return (
    <StyledContainer className={classes.root}>
      <Box height={32} width={53.33} overflow="hidden" mx="auto">
        <Logo
          height="28px"
          variant={theme.palette.mode === 'dark' ? 'light' : 'dark'}
          width="auto"
        />
      </Box>
      <Typography component="div" className={classes.welcome}>
        Welcome back
      </Typography>
      <Typography variant="h3" mb={'49px'}>
        Please sign in
      </Typography>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={EmailPasswordSchema}
        onSubmit={onSubmitHelper((values) => {
          const email = values.email;
          const emailDomain = email.substring(
            email.indexOf('@') + 1,
            email.length
          );

          if (emailDomain === 'fable.co') {
            alert('Please use social logins with company email');
          } else {
            firebase
              .auth()
              .signInWithEmailAndPassword(values.email, values.password)
              .then(({ user }) => user.getIdToken(true).then(handleToken));
          }
        })}
      >
        {({ isSubmitting, errors, touched, status }) => (
          <Form>
            <RenderErrors status={status} errors={errors} touched={touched} />
            <FormRow>
              <TextField
                className={clsx(classes.textField, classes.emailField)}
                name="email"
                type="email"
                label="Email"
                placeholder={FORM_PLACEHOLDERS.email}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </FormRow>
            <FormRow>
              <PasswordField
                className={`${classes.textField}`}
                name="password"
                label="Password"
                placeholder={FORM_PLACEHOLDERS.password}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </FormRow>
            <FormRow>
              <SubmitButton
                loading={isSubmitting}
                className={`${classes.submitButton} ${classes.emailButton}`}
              >
                Sign in with email
              </SubmitButton>
            </FormRow>
            <Typography component="div" className={classes.agreement}>
              By signing up, you agree to{' '}
              <A color="primary.main" href={URLS.terms} target="_blank">
                Fable’s Terms
                <br /> of Service
              </A>{' '}
              and{' '}
              <A color="primary.main" href={URLS.privacy} target="_blank">
                Privacy Policy
              </A>
              .
            </Typography>
            <FormRow>
              <RenderProvider
                classNames={`${classes.submitButton} ${classes.googleButton}`}
                providerName="Google"
                redirectPath="/"
              />
            </FormRow>
            <FormRow>
              <RenderProvider
                classNames={`${classes.submitButton} ${classes.appleButton}`}
                providerName="Apple"
                redirectPath="/"
              />
            </FormRow>
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
}
