import {
  Radio,
  FormControl,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material';

export default function RadioGroup({
  name,
  title,
  options,
  value,
  setFieldValue,
}) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{title}</FormLabel>
      <MuiRadioGroup
        name={name}
        value={value}
        onChange={(event) => {
          setFieldValue(name, event.currentTarget.value);
        }}
      >
        {options?.map((option) => (
          <FormControlLabel
            key={option.name}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
