import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import ErrorBox from '../../components/atoms/ErrorBox';
import Loading from '../../components/atoms/Loading';
import Button from '../../components/atoms/form/Button';
import useSearchVariables from '../../hooks/useSearchVariables';
import AccountOnboardingQuery from '../../state/modules/connect/AccountOnboardingQuery';

export default function PayoutOnboarding() {
  const { loading, error, data } = useQuery(AccountOnboardingQuery, {
    fetchPolicy: 'network-only',
  });
  const params = useSearchVariables();

  if (error) {
    return <ErrorBox error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Box sx={{ maxWidth: 600 }}>
      <Typography variant="h3" sx={{ mb: 4 }}>
        Welcome to Fable Payouts
      </Typography>

      {params.refresh && (
        <Typography
          variant="body"
          sx={{ mb: 4, color: 'error.main' }}
          component="p"
        >
          Something went wrong with Stripe setup, please try connecting again.
        </Typography>
      )}

      <Typography variant="body" sx={{ mb: 4 }} component="p">
        Click below to connect your bank account to Stripe, for instant payouts
        from Fable.
      </Typography>

      <Box>
        <Button href={data.payout_account.onboarding_link.url} target="_blank">
          Get Started
        </Button>
      </Box>
    </Box>
  );
}
