import { gql } from '@apollo/client';

import CompanyEngagementSummary from './CompanyEngagementSummaryFragment';

export default gql`
  query ($company_id: String!, $start: Date!, $end: Date!) {
    company_engagement_summary(
      company_id: $company_id
      start: $start
      end: $end
    ) {
      ...CompanyEngagementSummary
    }
  }
  ${CompanyEngagementSummary}
`;
