import React from 'react';
import { Navigate } from 'react-router';
import { Route } from 'react-router';
import { Routes } from 'react-router';

import BookDetail from './BookDetail';
import BookIndex from './BookIndex';

export default function BookRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<BookIndex />} />
        <Route path=":id" element={<BookDetail />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </div>
  );
}
