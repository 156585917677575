import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function NotFoundPage() {
  return (
    <Container maxWidth="sm">
      <Typography variant="h2" align="center" gutterBottom>
        Page Not Found
      </Typography>
    </Container>
  );
}
