import { gql } from '@apollo/client';

export default gql`
  fragment ClubEngagementSummary on ClubEngagementSummary {
    new_members_unique_count
    total_members_unique_count
    commenting_unique_count
    reacting_unique_count
    reading_unique_count
    highlighting_unique_count
    unique_engaged_count
  }
`;
