import Link from './Link';

export default function Logo(props) {
  return (
    <Link to="/">
      <img
        {...props}
        src={`/images/fable-logo-${props.variant}.svg`}
        alt="logo"
      />
    </Link>
  );
}
