import { useQuery } from '@apollo/client';
import IconComment from '@mui/icons-material/Comment';
import IconForum from '@mui/icons-material/Forum';
import IconBook from '@mui/icons-material/MenuBook';
import IconPeople from '@mui/icons-material/People';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import compareAsc from 'date-fns/compareAsc';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ErrorBox from '../../components/atoms/ErrorBox';
import Loading from '../../components/atoms/Loading';
import DateFilter from '../../components/molecules/filter/DateFilter';
import FilterBar from '../../components/molecules/filter/FilterBar';
import DashboardCard from '../../components/organisms/dashboard/DashboardCard';
import DashboardMetric from '../../components/organisms/dashboard/DashboardMetric';
import { useAuth } from '../../hooks/useAuth';
import useSearchVariables from '../../hooks/useSearchVariables';
import CompanyEngagementSummaryQuery from '../../state/modules/engagement/CompanyEngagementSummaryQuery';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { DATES, FONT_FAMILIES, LAYOUT } from '../../utils/constants';
import { formatDateParam, parseDateTime } from '../../utils/date';
import { formatNumberReadable } from '../../utils/number';

const PREFIX = 'EnagementDashboard';

const classes = {
  dashboardTemplate: `${PREFIX}-dashboardTemplate`,
  dashboardDivider: `${PREFIX}-dashboardDivider`,
  dateFilters: `${PREFIX}-dateFilters`,
  cardIcon: `${PREFIX}-cardIcon`,
  cardMetricDivider: `${PREFIX}-cardMetricDivider`,
  cardInfoIcon: `${PREFIX}-cardInfoIcon`,
  cardGrid: `${PREFIX}-cardGrid`,
  cardGridSpan: `${PREFIX}-cardGridSpan`,
  cardGridSpanFooter: `${PREFIX}-cardGridSpanFooter`,
  cardGridSpanCard: `${PREFIX}-cardGridSpanCard`,
  alert: `${PREFIX}-alert`,
  dateAlert: `${PREFIX}-dateAlert`,
  largeCenteredCard: `${PREFIX}-largeCenteredCard`,
};

const StyledDefaultTemplate = styled(DefaultTemplate)(({ theme }) => ({
  [`&.${classes.dashboardTemplate}`]: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: `calc(${LAYOUT.pageMaxWidth}px + ${theme.spacing(4)})`,
    },
  },

  [`& .${classes.dashboardDivider}`]: {
    borderColor: theme.palette.fable.graySmoke,
    opacity: 1,
  },

  [`& .${classes.dateFilters}`]: {
    '& .MuiFormControl-root': {
      maxWidth: '100%',
      [theme.breakpoints.only('xs')]: {
        '&:first-child': {
          marginBottom: theme.spacing(1),
        },
      },
      [theme.breakpoints.up('sm')]: {
        whiteSpace: 'nowrap',
        maxWidth: '200px',
        '&:first-child': {
          marginRight: theme.spacing(1),
        },
      },
    },
  },

  [`& .${classes.cardIcon}`]: {
    borderRadius: '28px',
    width: '80px',
    height: '80px',
    display: 'flex',
    position: 'absolute',
    right: theme.spacing(3),
    '& .MuiSvgIcon-root': {
      margin: 'auto',
    },
  },

  [`& .${classes.cardMetricDivider}`]: {
    borderColor: theme.palette.fable.graySmoke,
    marginTop: theme.spacing(3),
    opacity: 1,
    width: '100%',
  },

  [`& .${classes.cardInfoIcon}`]: {
    marginRight: '11px',
  },

  [`& .${classes.cardGrid}`]: {
    [theme.breakpoints.up('md')]: {
      '& > .MuiGrid-item:nth-child(odd)': {
        paddingRight: '12px',
      },
      '& > .MuiGrid-item:nth-child(even)': {
        paddingLeft: '12px',
      },
    },
  },

  [`& .${classes.cardGridSpan}`]: {
    [theme.breakpoints.up('md')]: {
      '& .MuiPaper-root': {
        display: 'flex',
        height: `calc((100%) - ${theme.spacing(9)})`,
        position: 'relative',
      },
    },
  },

  [`& .${classes.cardGridSpanFooter}`]: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      width: '100%',
    },
  },

  [`& .${classes.cardGridSpanCard}`]: {
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      height: '100%',
      top: theme.spacing(3),
      right: theme.spacing(3),
      left: theme.spacing(3),
      '& $cardIcon': {
        right: 0,
      },
    },
  },

  [`& .${classes.alert}`]: {
    '& .MuiAlert-action': {
      alignItems: 'center',
    },
    '& .MuiButton-label': {
      fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
      fontSize: '14px',
      letterSpacing: '1px',
    },
  },

  [`& .${classes.dateAlert}`]: {
    color: theme.palette.fable.blackSwan,
    background: theme.palette.fable.pinkMoon,
    '& .MuiButton-label': {
      color: theme.palette.fable.blackSwan,
    },
  },

  [`& .${classes.largeCenteredCard}`]: {
    textAlign: 'center',
  },
}));

const RenderCardIcon = ({
  backgroundColor,
  fill,
  flexShrink,
  iconComponent,
  iconClass,
  iconHeight,
  iconWidth,
  iconSize,
}) => {
  const IconComponent = iconComponent;
  return (
    <Box
      className={iconClass}
      display="inherit"
      flexShrink={flexShrink}
      style={{
        backgroundColor: backgroundColor,
        height: iconHeight,
        width: iconWidth,
      }}
    >
      <IconComponent
        style={{
          fill,
          width: iconSize,
          height: iconSize,
        }}
      />
    </Box>
  );
};

export default function EngagementDashboard() {
  const theme = useTheme();
  const auth = useAuth();

  const defaultStartDateParam = formatDateParam(DATES.reporting_start);
  const defaultEndDateParam = formatDateParam(DATES.reporting_end);
  const searchVariables = useSearchVariables();
  const [searchParams, setSearchParams] = useSearchParams();

  const skip =
    !auth.currentUser?.company?.id ||
    !searchVariables.start ||
    !searchVariables.end;

  const { loading, error, data } = useQuery(CompanyEngagementSummaryQuery, {
    variables: {
      company_id: R.pathOr(null, ['company', 'id'], auth.currentUser),
      start: searchVariables.start,
      end: searchVariables.end,
    },
    skip,
  });

  useEffect(() => {
    if (!searchVariables.start || !searchVariables.end) {
      searchParams.set('start', defaultStartDateParam);
      searchParams.set('end', defaultEndDateParam);
      setSearchParams(searchParams, { replace: true });
      searchVariables.start = defaultStartDateParam;
      searchVariables.end = defaultEndDateParam;
    }

    // Manages "end before start" alert to share "export csv" alert space
    // Wouldn't need to if makeStyles had working '&[opacity=0]' selectors
    if (
      compareAsc(
        parseDateTime(searchVariables.start),
        parseDateTime(searchVariables.end)
      ) === 1
    ) {
      setRemoveDateAlert(false);
      setShowDateAlert(true);
    } else {
      setRemoveDateAlert(true);
      setShowDateAlert(false);
    }
  }, [
    searchVariables,
    defaultEndDateParam,
    defaultStartDateParam,
    searchParams,
    setSearchParams,
  ]);

  const [showDateAlert, setShowDateAlert] = useState(false);
  const [removeDateAlert, setRemoveDateAlert] = useState(true);

  const reactingUniqueCount = R.pathOr(
    0,
    ['company_engagement_summary', 'reacting_unique_count'],
    data
  );
  const commentingUniqueCount = R.pathOr(
    0,
    ['company_engagement_summary', 'commenting_unique_count'],
    data
  );
  const highlightingUniqueCount = R.pathOr(
    0,
    ['company_engagement_summary', 'highlighting_unique_count'],
    data
  );
  const readingUniqueCount = R.pathOr(
    0,
    ['company_engagement_summary', 'reading_unique_count'],
    data
  );
  const uniqueEngagedCount = R.pathOr(
    0,
    ['company_engagement_summary', 'unique_engaged_count'],
    data
  );
  const totalUserCcount = R.pathOr(
    0,
    ['company_engagement_summary', 'total_user_count'],
    data
  );
  const totalMessageCount = R.pathOr(
    0,
    ['company_engagement_summary', 'total_message_count'],
    data
  );

  if (error) {
    return <ErrorBox error={error} />;
  }

  if (loading || skip) {
    return <Loading />;
  }

  return (
    <StyledDefaultTemplate className={classes.dashboardTemplate}>
      <Grid
        container
        alignItems="center"
        direction="row"
        spacing={0}
        justifyContent="center"
        p={2}
      >
        <Grid container spacing={0}>
          <Grid height={48} item xs={12}>
            <Fade
              in={showDateAlert}
              onExited={() => setRemoveDateAlert(true)}
              style={{ display: `${removeDateAlert ? 'none' : 'flex'}` }}
            >
              <Alert
                className={`${classes.alert} ${classes.dateAlert}`}
                icon={false}
                severity="error"
                action={
                  <Button color="inherit" size="small">
                    OK
                  </Button>
                }
                onClick={() => {
                  setShowDateAlert(false);
                }}
              >
                Please select an end date that occurs after your selected start
                date.
              </Alert>
            </Fade>
          </Grid>
          <Grid item xs={12}>
            <FilterBar sx={{ height: { xs: 'auto', sm: '80px' } }}>
              <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                pb={1}
                pl={3}
                height="100%"
              >
                <Grid item className={classes.dateFilters}>
                  <DateFilter
                    name="start"
                    label="Start date"
                    defaultDate={defaultStartDateParam}
                    maxDate={formatDateParam(DATES.today, 'MM-dd-yyyy')}
                  />
                  <DateFilter
                    name="end"
                    label="End date"
                    defaultDate={defaultEndDateParam}
                    maxDate={formatDateParam(DATES.today, 'MM-dd-yyyy')}
                  />
                </Grid>
              </Grid>
            </FilterBar>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Divider className={classes.dashboardDivider} />
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.cardGrid}>
          <Grid item xs={12} md={12}>
            <DashboardCard
              title="Total engagement"
              className={classes.largeCenteredCard}
            >
              <Grid container justifyContent="center">
                <DashboardMetric
                  title="Members engaged"
                  rawValue={uniqueEngagedCount}
                  value={formatNumberReadable(uniqueEngagedCount)}
                  variant="large"
                />
              </Grid>
            </DashboardCard>
          </Grid>
        </Grid>

        <Grid container spacing={0} className={classes.cardGrid}>
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={
                <RenderCardIcon
                  backgroundColor={theme.palette.fable.tussockLight}
                  fill={theme.palette.fable.whiteFang}
                  iconClass={classes.cardIcon}
                  iconComponent={IconForum}
                  iconSize={'50%'}
                />
              }
              title="Social engagement"
            >
              <Grid container>
                <DashboardMetric
                  title="Members posted"
                  rawValue={commentingUniqueCount}
                  value={formatNumberReadable(commentingUniqueCount)}
                />
                <Divider className={classes.cardMetricDivider} />
              </Grid>
              <Grid container>
                <DashboardMetric
                  title="Members reacted"
                  rawValue={reactingUniqueCount}
                  value={formatNumberReadable(reactingUniqueCount)}
                />
                <Divider className={classes.cardMetricDivider} />
              </Grid>
            </DashboardCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={
                <RenderCardIcon
                  backgroundColor={theme.palette.fable.tussockLight}
                  fill={theme.palette.fable.whiteFang}
                  iconClass={classes.cardIcon}
                  iconComponent={IconBook}
                  iconSize={'50%'}
                />
              }
              title="Book engagement"
            >
              <Grid container>
                <DashboardMetric
                  title="Members opened the book"
                  rawValue={readingUniqueCount}
                  value={formatNumberReadable(readingUniqueCount)}
                />
                <Divider className={classes.cardMetricDivider} />
              </Grid>

              <Grid container>
                <DashboardMetric
                  title="Members made a highlight"
                  rawValue={highlightingUniqueCount}
                  value={formatNumberReadable(highlightingUniqueCount)}
                />
                <Divider className={classes.cardMetricDivider} />
              </Grid>
            </DashboardCard>
          </Grid>
          {/* We exploit MUI row overflow to produce a flex-based row-span */}
          <Grid item xs={12} md={6} className={classes.cardGridSpan}>
            <DashboardCard
              icon={
                <RenderCardIcon
                  backgroundColor={theme.palette.fable.shamrockLight}
                  fill={theme.palette.fable.whiteFang}
                  iconClass={classes.cardIcon}
                  iconComponent={IconComment}
                  iconSize={'50%'}
                />
              }
              title="Messages posted"
              variant={classes.cardGridSpanCard}
            >
              <Grid container>
                <DashboardMetric
                  title="Total Messages"
                  rawValue={totalMessageCount}
                  value={formatNumberReadable(totalMessageCount)}
                />
                <Divider className={classes.cardMetricDivider} />
              </Grid>
            </DashboardCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={
                <RenderCardIcon
                  backgroundColor={theme.palette.fable.denimLight}
                  fill={theme.palette.fable.whiteFang}
                  iconClass={classes.cardIcon}
                  iconComponent={IconPeople}
                  iconSize={'50%'}
                />
              }
              title="Club members"
            >
              <DashboardMetric
                title="Seats used"
                rawValue={totalUserCcount}
                value={formatNumberReadable(totalUserCcount)}
              />
            </DashboardCard>
          </Grid>
        </Grid>
      </Grid>
    </StyledDefaultTemplate>
  );
}
