import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import MuiAvatar from '@mui/material/Avatar';

const Avatar = styled(MuiAvatar, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'size',
})`
  && {
    ${(props) => props.size && 'width: ' + props.size};
    ${(props) => props.size && 'height: ' + props.size};
  }
`;

export default Avatar;
