import { gql } from '@apollo/client';

import ErrorFragment from '../ErrorFragment';
import UserFragment from './UserFragment';

export default gql`
  mutation ($input: UserMutationInput!) {
    user(input: $input) {
      result {
        ...User
      }
      errors {
        ...Errors
      }
    }
  }
  ${UserFragment}
  ${ErrorFragment}
`;
