import Typography from '@mui/material/Typography';
import React from 'react';

export default function BookIndex() {
  return (
    <>
      <Typography variant="h2" align="center">
        BookDetail
      </Typography>
    </>
  );
}
