import React from 'react';

import Select from '../../atoms/form/Select';
import Filter from './Filter';

export default function SelectFilter({ name, ...props }) {
  return (
    <Filter name={name} defaultValue="">
      <Select type="search" {...props} />
    </Filter>
  );
}
