import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import * as R from 'ramda';
import React from 'react';

import useAutomaticRefetch from '../../hooks/useRefetch';
import useSearchVariables from '../../hooks/useSearchVariables';
import { buildPageInfoGetter } from '../../utils/graphql';
import { buildRowGetter } from '../../utils/graphql';
import ErrorBox from '../atoms/ErrorBox';
import Loading from '../atoms/Loading';
import Button from '../atoms/form/Button';
import Table from '../molecules/Table';

export default function QueryTable({
  query,
  queryNode,
  cols,
  getRows,
  mapRows,
  getPageInfo,
  additionalQueryVariables,
}) {
  const searchVariables = useSearchVariables();
  const { loading, error, data, fetchMore, refetch } = useQuery(query, {
    variables: {
      ...searchVariables,
      ...(additionalQueryVariables ? additionalQueryVariables : {}),
    },
  });
  useAutomaticRefetch(refetch, searchVariables);

  if (!getPageInfo) {
    getPageInfo = buildPageInfoGetter(queryNode);
  }

  if (!getRows) {
    getRows = buildRowGetter(queryNode);
  }

  if (!mapRows) {
    mapRows = R.identity;
  }

  const dataToRows = R.compose(mapRows, getRows);

  if (error) {
    return <ErrorBox error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const pageInfo = getPageInfo(data);

  return (
    <>
      <Table rows={dataToRows(data)} cols={cols} />
      {pageInfo.hasNextPage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
          <Button
            onClick={() =>
              fetchMore({
                variables: {
                  after: pageInfo.endCursor,
                  ...searchVariables,
                },
              })
            }
          >
            Fetch More
          </Button>
        </Box>
      )}
    </>
  );
}
