import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import * as R from 'ramda';
import React from 'react';

import Link from '../../components/atoms/Link';
import FilterBar from '../../components/molecules/filter/FilterBar';
import ResetFilter from '../../components/molecules/filter/ResetFilter';
import TextFilter from '../../components/molecules/filter/TextFilter';
import QueryTable from '../../components/organisms/QueryTable';
import AllBookListsQuery from '../../state/modules/book_list/AllBookListsQuery';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { formatDateTime, parseDateTime } from '../../utils/date';

const PREFIX = 'BookListIndex';

const classes = {
  search: `${PREFIX}-search`,
};

const StyledDefaultTemplate = styled(DefaultTemplate)(({ theme }) => ({
  // @TODO: Switch to "sx" when MUI enables responsive Grid spacing
  // https://github.com/mui-org/material-ui/issues/6140#issuecomment-802787556
  [`& .${classes.search}`]: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
      paddingRight: theme.spacing(2),
    },
  },
}));

const queryNode = 'all_book_lists';

const cols = [
  {
    getter: (item) => (
      <Link color="secondary.main" to={item.id}>
        {item.name}
      </Link>
    ),
    name: 'Name',
    flex: 3,
  },
  { getter: R.prop('description'), name: 'Description', flex: 3 },
  {
    getter: R.compose(formatDateTime, R.prop('feature_at')),
    name: 'Feature At',
    flex: 2,
  },
  {
    getter: R.compose(R.length, R.prop('entries')),
    name: 'Books',
    flex: 2,
  },
  { getter: R.prop('media_type'), name: 'Media Type', flex: 2 },
  { getter: R.prop('editorial_ordering'), name: 'Editorial Ordering', flex: 1 },
];

const mapRows = R.map(R.over(R.lensProp('feature_at'), parseDateTime));

export default function BookListIndex() {
  return (
    <StyledDefaultTemplate>
      <FilterBar>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} className={classes.search}>
            <TextFilter name="q" label="Search" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ResetFilter />
          </Grid>
        </Grid>
      </FilterBar>
      <QueryTable
        cols={cols}
        queryNode={queryNode}
        mapRows={mapRows}
        query={AllBookListsQuery}
      />
    </StyledDefaultTemplate>
  );
}
