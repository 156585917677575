import IconKeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import IconKeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers-pro';
import * as RA from 'ramda-adjunct';
import React, { useState } from 'react';

import useSearchVariables from '../../../hooks/useSearchVariables';
import { FONT_FAMILIES } from '../../../utils/constants';
import { formatDateParam, parseDateTime } from '../../../utils/date';
import Filter from './Filter';

const PREFIX = 'DateFilter';

const classes = {
  dateInput: `${PREFIX}-dateInput`,
  datePopper: `${PREFIX}-datePopper`,
};

const StyledFilter = styled(Filter)(({ theme }) => ({
  [`& .${classes.dateInput}`]: {
    '&:focus': {
      outlineColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: theme.palette.fable.grayStrong,
      userSelect: 'none',
      cursor: 'default',
      caretColor: 'transparent',
      '&::selection': {
        background: 'transparent',
      },
    },
    '& .MuiOutlinedInput-root:focus': {
      outlineColor: 'transparent',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.fable.grayMedium}`,
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.fable.mahogany}`,
    },
    '& .MuiSvgIcon-root': {
      cursor: 'default',
    },
    '& .MuiOutlinedInput-root:hover > fieldset': {
      border: `1px solid ${theme.palette.fable.grayMedium}`,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.fable.grayHurricane,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.fable.grayHurricane,
    },
    '& .MuiOutlinedInput-notchedOutline > legend': {
      display: 'none',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(2),
      cursor: 'default',
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: `translate(0, ${theme.spacing(1)})`,
      fontSize: '12px',
      left: theme.spacing(2),
    },
    '& .MuiOutlinedInput-root > fieldset': {
      borderColor: theme.palette.fable.grayMedium,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.fable.grayMedium,
    },
  },

  [`& .${classes.datePopper}`]: {
    zIndex: '1200',
    '& .MuiPaper-root': {
      width: '256px',
      height: '286px',
      overflow: 'hidden',
      '& > :first-child > :first-child': {
        width: 'auto',
      },
    },
    '& .MuiCalendarPicker-root': {
      width: '256px',
      '&:first-child': {
        fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
        fontSize: '14px',
        color: theme.palette.fable.grayHurricane,
      },
      '& .MuiTypography-caption': {
        color: theme.palette.fable.grayHurricane,
        fontSize: '12px',
        width: '28px',
        paddingTop: '12px',
        height: 'auto',
      },
      /*
        @TODO: Figure out a solution that works with compiled JSS as there
        are not any "PrivatePickersCalendar-" classes in the production
        runtime. One of these can be solved by passing a className to
        to a custom "renderDay" renderer. The other may require some even
        hackier CSS seletors.
      */
      '& [class^="PrivatePickersCalendar-weekContainer"]': {
        height: '100%',
        paddingTop: '7px',
      },
      '& [class^="PrivatePickersCalendar-week"]': {
        margin: '0 0 4px 0 ',
        '& .MuiPickersDay-root': {
          '& button': {
            width: '28px',
            height: '28px',
            fontSize: '12px',
          },
        },
      },
    },
    '& .MuiPickersDay-root': {
      width: '28px',
      height: '28px',
    },
    '& .MuiPickersDay-day': {
      transform: 'scale(1)',
      width: '28px',
      height: '28px',
    },
    '& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: theme.palette.fable.green,
    },
    '& .MuiPickersDay-today:not(.Mui-selected)': {
      border: `1px solid ${theme.palette.fable.grayStrong}`,
    },
    '& .MuiPickersDay-root:focus:not(.Mui-selected)': {
      backgroundColor: 'transparent',
    },
  },
}));

const RenderToggledIcon = ({ toggleValue, iconOn, iconOff, fill }) => {
  const IconComponent = toggleValue ? iconOn : iconOff;
  return <IconComponent style={{ fill }} />;
};

export default function DateFilter({ name, defaultDate, ...props }) {
  const searchVariables = useSearchVariables();

  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);

  // For fixing some bug in the picker prevents opening by clicking upon input
  const [elOpenButton, setOpenButton] = useState(null);

  return (
    <StyledFilter
      name={name}
      valueMapper={parseDateTime}
      onChangeMapper={(value) => {
        let newValue = defaultDate;
        if (RA.isDate(value) && new Date(value).toString() !== 'Invalid Date') {
          newValue = formatDateParam(value);
        }
        return newValue;
      }}
    >
      <DatePicker
        {...props}
        views={['day']}
        inputFormat="MM/dd/yyyy"
        PopperProps={{
          className: classes.datePopper,
          placement: 'bottom',
        }}
        renderInput={(inputProps) => (
          <TextField
            {...inputProps}
            className={classes.dateInput}
            helperText={null}
            variant="outlined"
            tabIndex="-1"
            onMouseDown={(e) => {
              if (elOpenButton !== null) {
                elOpenButton.click();
              }
            }}
          />
        )}
        openPickerIcon={
          <RenderToggledIcon
            iconOn={IconKeyboardArrowUp}
            iconOff={IconKeyboardArrowDown}
            fill={theme.palette.fable.blackSwan}
            toggleValue={isOpen}
          />
        }
        OpenPickerButtonProps={{
          disableRipple: true,
          ref: (node) => {
            setOpenButton(node);
          },
        }}
        onChange={() => {}}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        value={
          searchVariables[name]
            ? parseDateTime(searchVariables[name])
            : defaultDate
        }
      />
    </StyledFilter>
  );
}
