import { useQuery } from '@apollo/client';
import IconInfo from '@mui/icons-material/InfoOutlined';
import IconLocalOffer from '@mui/icons-material/LocalOffer';
import IconPayments from '@mui/icons-material/Payments';
import IconPeople from '@mui/icons-material/People';
import IconVisibility from '@mui/icons-material/Visibility';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import compareAsc from 'date-fns/compareAsc';
import * as R from 'ramda';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ErrorBox from '../../components/atoms/ErrorBox';
import Loading from '../../components/atoms/Loading';
import DateFilter from '../../components/molecules/filter/DateFilter';
import FilterBar from '../../components/molecules/filter/FilterBar';
import DashboardCard from '../../components/organisms/dashboard/DashboardCard';
import DashboardCardFooter from '../../components/organisms/dashboard/DashboardCardFooter';
import DashboardInfo from '../../components/organisms/dashboard/DashboardInfo';
import DashboardMetric from '../../components/organisms/dashboard/DashboardMetric';
import { useAuth } from '../../hooks/useAuth';
import useSearchVariables from '../../hooks/useSearchVariables';
import useStorage from '../../hooks/useStorage';
import AffiliateSummaryQuery from '../../state/modules/affiliate/AffiliateSummaryQuery';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { DATES } from '../../utils/constants';
import { FONT_FAMILIES } from '../../utils/constants';
import { LAYOUT } from '../../utils/constants';
import { REPORT_MESSAGES } from '../../utils/constants';
import { REPORT_TYPES } from '../../utils/constants';
import { formatDateParam } from '../../utils/date';
import { parseDateTime } from '../../utils/date';
import { formatNumberReadable } from '../../utils/number';
import { formatStringDollars } from '../../utils/number';

const PREFIX = 'DashboardIndex';

const classes = {
  dashboardTemplate: `${PREFIX}-dashboardTemplate`,
  dashboardDivider: `${PREFIX}-dashboardDivider`,
  dateFilters: `${PREFIX}-dateFilters`,
  cardIcon: `${PREFIX}-cardIcon`,
  cardMetricDivider: `${PREFIX}-cardMetricDivider`,
  cardInfoIcon: `${PREFIX}-cardInfoIcon`,
  cardGrid: `${PREFIX}-cardGrid`,
  cardGridSpan: `${PREFIX}-cardGridSpan`,
  cardGridSpanFooter: `${PREFIX}-cardGridSpanFooter`,
  cardGridSpanCard: `${PREFIX}-cardGridSpanCard`,
  alert: `${PREFIX}-alert`,
  csvAlert: `${PREFIX}-csvAlert`,
  dateAlert: `${PREFIX}-dateAlert`,
};

const StyledDefaultTemplate = styled(DefaultTemplate)(({ theme }) => ({
  [`&.${classes.dashboardTemplate}`]: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: `calc(${LAYOUT.pageMaxWidth}px + ${theme.spacing(4)})`,
    },
  },

  [`& .${classes.dashboardDivider}`]: {
    borderColor: theme.palette.fable.graySmoke,
    opacity: 1,
  },

  [`& .${classes.dateFilters}`]: {
    '& .MuiFormControl-root': {
      maxWidth: '100%',
      [theme.breakpoints.only('xs')]: {
        '&:first-child': {
          marginBottom: theme.spacing(1),
        },
      },
      [theme.breakpoints.up('sm')]: {
        whiteSpace: 'nowrap',
        maxWidth: '200px',
        '&:first-child': {
          marginRight: theme.spacing(1),
        },
      },
    },
  },

  [`& .${classes.cardIcon}`]: {
    borderRadius: '28px',
    width: '80px',
    height: '80px',
    display: 'flex',
    position: 'absolute',
    right: theme.spacing(3),
    '& .MuiSvgIcon-root': {
      margin: 'auto',
    },
  },

  [`& .${classes.cardMetricDivider}`]: {
    borderColor: theme.palette.fable.graySmoke,
    marginTop: theme.spacing(3),
    opacity: 1,
    width: '100%',
  },

  [`& .${classes.cardInfoIcon}`]: {
    marginRight: '11px',
  },

  [`& .${classes.cardGrid}`]: {
    [theme.breakpoints.up('md')]: {
      '& > .MuiGrid-item:nth-child(odd)': {
        paddingRight: '12px',
      },
      '& > .MuiGrid-item:nth-child(even)': {
        paddingLeft: '12px',
      },
    },
  },

  [`& .${classes.cardGridSpan}`]: {
    [theme.breakpoints.up('md')]: {
      '& .MuiPaper-root': {
        display: 'flex',
        height: `calc((100% * 2) - ${theme.spacing(9)})`,
        position: 'relative',
      },
    },
  },

  [`& .${classes.cardGridSpanFooter}`]: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      width: '100%',
    },
  },

  [`& .${classes.cardGridSpanCard}`]: {
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      height: '100%',
      top: theme.spacing(3),
      right: theme.spacing(3),
      left: theme.spacing(3),
      '& $cardIcon': {
        right: 0,
      },
    },
  },

  [`& .${classes.alert}`]: {
    '& .MuiAlert-action': {
      alignItems: 'center',
    },
    '& .MuiButton-label': {
      fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
      fontSize: '14px',
      letterSpacing: '1px',
    },
  },

  [`& .${classes.csvAlert}`]: {
    color: theme.palette.fable.whiteFang,
    background: theme.palette.fable.grayStrong,
    '& .MuiButton-label': {
      color: theme.palette.fable.shamrockLight,
    },
  },

  [`& .${classes.dateAlert}`]: {
    color: theme.palette.fable.blackSwan,
    background: theme.palette.fable.pinkMoon,
    '& .MuiButton-label': {
      color: theme.palette.fable.blackSwan,
    },
  },
}));

const RenderCardIcon = ({
  backgroundColor,
  fill,
  flexShrink,
  iconComponent,
  iconClass,
  iconHeight,
  iconWidth,
  iconSize,
}) => {
  const IconComponent = iconComponent;
  return (
    <Box
      className={iconClass}
      display="inherit"
      flexShrink={flexShrink}
      style={{
        backgroundColor: backgroundColor,
        height: iconHeight,
        width: iconWidth,
      }}
    >
      <IconComponent
        style={{
          fill,
          width: iconSize,
          height: iconSize,
        }}
      />
    </Box>
  );
};

export default function DashboardIndex() {
  const theme = useTheme();
  const auth = useAuth();

  const defaultStartDateParam = formatDateParam(DATES.reporting_start);
  const defaultEndDateParam = formatDateParam(DATES.reporting_end);
  const searchVariables = useSearchVariables();
  const [searchParams, setSearchParams] = useSearchParams();

  const skip =
    !auth.currentUser?.company?.id ||
    !searchVariables.start ||
    !searchVariables.end;

  const { loading, error, data } = useQuery(AffiliateSummaryQuery, {
    variables: {
      company_id: R.pathOr(null, ['company', 'id'], auth.currentUser),
      start: searchVariables.start,
      end: searchVariables.end,
    },
    skip,
  });

  useEffect(() => {
    if (!searchVariables.start || !searchVariables.end) {
      searchParams.set('start', defaultStartDateParam);
      searchParams.set('end', defaultEndDateParam);
      setSearchParams(searchParams, { replace: true });
      searchVariables.start = defaultStartDateParam;
      searchVariables.end = defaultEndDateParam;
    }

    // Manages "end before start" alert to share "export csv" alert space
    // Wouldn't need to if makeStyles had working '&[opacity=0]' selectors
    if (
      compareAsc(
        parseDateTime(searchVariables.start),
        parseDateTime(searchVariables.end)
      ) === 1
    ) {
      setRemoveDateAlert(false);
      setShowDateAlert(true);
    } else {
      setRemoveDateAlert(true);
      setShowDateAlert(false);
    }
  }, [
    searchVariables,
    defaultEndDateParam,
    defaultStartDateParam,
    searchParams,
    setSearchParams,
  ]);

  const [csvAlertDismissTime, setCsvAlertDismissTime] = useStorage(
    'csvAlertDismissTime',
    null
  );
  const [showCsvAlert, setShowCsvAlert] = useState(
    csvAlertDismissTime === null ||
      new Date(csvAlertDismissTime).getTime() <
        new Date(auth.authTime).getTime()
  );
  const [removeCsvAlert, setRemoveCsvAlert] = useState(false);
  const [showDateAlert, setShowDateAlert] = useState(false);
  const [removeDateAlert, setRemoveDateAlert] = useState(true);

  const exportCsvHtml = <span>Export .CSV</span>;
  const paymentsInfoHtml = (
    <span>
      This is an estimation and final revenue will be presented in the End of
      the Month report.
    </span>
  );

  // Club views
  const pageArray = R.pathOr(null, ['affiliate_summary', 'page'], data);
  const pages = pageArray
    ? R.compose(R.map(R.pathOr(0, ['stats', 'sum_count'])))
    : null;
  const mappedPages = pages ? pages(pageArray) : null;
  var pageSumCount = mappedPages ? R.reduce(R.add, 0, mappedPages) : 0;

  // Paid members
  const membershipArray = R.pathOr(
    null,
    ['affiliate_summary', 'membership'],
    data
  );

  const membershipAndroid = membershipArray
    ? membershipArray.find((item) => {
        return item.source === 'android';
      }) || null
    : null;
  const membershipAndroidCount = R.pathOr(
    0,
    ['stats', 'sum_count'],
    membershipAndroid
  );
  const membershipAndroidValue =
    '' + R.pathOr(0, ['stats', 'sum_value'], membershipAndroid);

  const membershipIos = membershipArray
    ? membershipArray.find((item) => {
        return item.source === 'ios';
      }) || null
    : null;
  const membershipIosCount = R.pathOr(0, ['stats', 'sum_count'], membershipIos);
  const membershipIosValue =
    '' + R.pathOr(0, ['stats', 'sum_value'], membershipIos);

  const membershipWeb = membershipArray
    ? membershipArray.find((item) => {
        return item.source === 'web';
      }) || null
    : null;
  const membershipWebCount = R.pathOr(0, ['stats', 'sum_count'], membershipWeb);
  const membershipWebValue =
    '' + R.pathOr(0, ['stats', 'sum_value'], membershipWeb);

  // Club members
  const purchaseArray = R.pathOr(null, ['affiliate_summary', 'purchase'], data);
  const purchaseItem =
    purchaseArray && purchaseArray.length > 0 ? purchaseArray[0] : null;
  const purchaseSumCount = R.pathOr(0, ['stats', 'sum_count'], purchaseItem);
  const purchaseSumValue =
    '' + R.pathOr(0, ['stats', 'sum_value'], purchaseItem);

  // Book sales
  const clubArray = R.pathOr(null, ['affiliate_summary', 'club_join'], data);
  const clubItem = clubArray && clubArray.length > 0 ? clubArray[0] : null;
  const clubJoinSumCount = R.pathOr(0, ['stats', 'sum_count'], clubItem);

  if (error) {
    return <ErrorBox error={error} />;
  }

  if (loading || skip) {
    return <Loading />;
  }

  return (
    <StyledDefaultTemplate className={classes.dashboardTemplate}>
      <Grid
        container
        alignItems="center"
        direction="row"
        spacing={0}
        justifyContent="center"
        p={2}
      >
        <Grid container spacing={0}>
          <Grid height={48} item xs={12}>
            <Fade
              in={showDateAlert}
              onExited={() => setRemoveDateAlert(true)}
              style={{ display: `${removeDateAlert ? 'none' : 'flex'}` }}
            >
              <Alert
                className={`${classes.alert} ${classes.dateAlert}`}
                icon={false}
                severity="error"
                action={
                  <Button color="inherit" size="small">
                    OK
                  </Button>
                }
                onClick={() => {
                  setShowDateAlert(false);
                }}
              >
                Please select an end date that occurs after your selected start
                date.
              </Alert>
            </Fade>
            <Fade
              in={!showDateAlert && showCsvAlert}
              onExited={() => setRemoveCsvAlert(true)}
              style={{ display: `${removeCsvAlert ? 'none' : 'flex'}` }}
            >
              <Alert
                className={`${classes.alert} ${classes.csvAlert}`}
                icon={false}
                severity="warning"
                action={
                  <Button color="inherit" size="small">
                    GOT IT
                  </Button>
                }
                onClick={() => {
                  setShowCsvAlert(false);
                  setCsvAlertDismissTime(new Date().toString());
                }}
              >
                Export .CSV to view all metrics with source.
              </Alert>
            </Fade>
          </Grid>
          <Grid item xs={12}>
            <FilterBar sx={{ height: { xs: 'auto', sm: '80px' } }}>
              <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                pb={1}
                pl={3}
                height="100%"
              >
                <Grid item className={classes.dateFilters}>
                  <DateFilter
                    name="start"
                    label="Start date"
                    defaultDate={defaultStartDateParam}
                    maxDate={formatDateParam(DATES.today, 'MM-dd-yyyy')}
                  />
                  <DateFilter
                    name="end"
                    label="End date"
                    defaultDate={defaultEndDateParam}
                    maxDate={formatDateParam(DATES.today, 'MM-dd-yyyy')}
                  />
                </Grid>
              </Grid>
            </FilterBar>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Divider className={classes.dashboardDivider} />
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.cardGrid}>
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={
                <RenderCardIcon
                  backgroundColor={theme.palette.fable.tussockLight}
                  fill={theme.palette.fable.whiteFang}
                  iconClass={classes.cardIcon}
                  iconComponent={IconVisibility}
                  iconSize={'50%'}
                />
              }
              title="Club views"
              subtitle="Total unique views of the club page"
            >
              <DashboardMetric
                title="Total Club Views"
                rawValue={pageSumCount}
                value={formatNumberReadable(pageSumCount)}
              />
              <DashboardCardFooter
                linkText={exportCsvHtml}
                reportType={REPORT_TYPES.affiliate_page_view}
                reportTitle="Club Views Report"
                reportMessagePending={REPORT_MESSAGES.pending}
                reportMessageComplete={REPORT_MESSAGES.complete}
              />
            </DashboardCard>
          </Grid>
          {/* We exploit MUI row overflow to produce a flex-based row-span */}
          <Grid item xs={12} md={6} className={classes.cardGridSpan}>
            <DashboardCard
              icon={
                <RenderCardIcon
                  backgroundColor={theme.palette.fable.shamrockLight}
                  fill={theme.palette.fable.whiteFang}
                  iconClass={classes.cardIcon}
                  iconComponent={IconPayments}
                  iconSize={'50%'}
                />
              }
              title="Member breakdown"
              subtitle="Totals for premium memberships attributed to the club"
              variant={classes.cardGridSpanCard}
            >
              <Grid container>
                <DashboardMetric
                  title="Total on Web"
                  rawValue={membershipWebCount}
                  value={formatNumberReadable(membershipWebCount)}
                />
                <DashboardMetric
                  title="Total gross revenue"
                  value={`$${formatStringDollars(membershipWebValue)}`}
                  variant="secondaryValue"
                />
                <Divider className={classes.cardMetricDivider} />
              </Grid>
              <Grid container>
                <DashboardMetric
                  title="Total on iOS"
                  rawValue={membershipIosCount}
                  value={formatNumberReadable(membershipIosCount)}
                />
                <DashboardMetric
                  title="Total gross revenue"
                  value={`$${formatStringDollars(membershipIosValue)}`}
                  variant="secondaryValue"
                />
                <Divider className={classes.cardMetricDivider} />
              </Grid>
              <Grid container>
                <DashboardMetric
                  title="Total on Android"
                  rawValue={membershipAndroidCount}
                  value={formatNumberReadable(membershipAndroidCount)}
                />
                <DashboardMetric
                  title="Total gross revenue"
                  value={`$${formatStringDollars(membershipAndroidValue)}`}
                  variant="secondaryValue"
                />
              </Grid>
              <DashboardInfo
                icon={
                  <RenderCardIcon
                    fill={theme.palette.fable.grayHurricane}
                    flexShrink={0}
                    iconClass={classes.cardInfoIcon}
                    iconComponent={IconInfo}
                    iconHeight="auto"
                    iconWidth="18.33px"
                    iconSize="121%"
                  />
                }
                message={paymentsInfoHtml}
              />
              <DashboardCardFooter
                className={classes.cardGridSpanFooter}
                linkText={exportCsvHtml}
                linkUrl="#"
                reportType={REPORT_TYPES.affiliate_subscription}
                reportTitle="Member Breakdown Report"
                reportMessagePending={REPORT_MESSAGES.pending}
                reportMessageComplete={REPORT_MESSAGES.complete}
              />
            </DashboardCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={
                <RenderCardIcon
                  backgroundColor={theme.palette.fable.denimLight}
                  fill={theme.palette.fable.whiteFang}
                  iconClass={classes.cardIcon}
                  iconComponent={IconPeople}
                  iconSize={'50%'}
                />
              }
              title="Club members"
              subtitle="Total number of reading club members"
            >
              <DashboardMetric
                title="Total members"
                rawValue={clubJoinSumCount}
                value={formatNumberReadable(clubJoinSumCount)}
              />
              <DashboardCardFooter
                linkText={exportCsvHtml}
                linkUrl="#"
                reportType={REPORT_TYPES.affiliate_club_member}
                reportTitle="Club Members Report"
                reportMessagePending={REPORT_MESSAGES.pending}
                reportMessageComplete={REPORT_MESSAGES.complete}
              />
            </DashboardCard>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={
                <RenderCardIcon
                  backgroundColor={theme.palette.fable.pinkMoon}
                  fill={theme.palette.fable.whiteFang}
                  iconClass={classes.cardIcon}
                  iconComponent={IconLocalOffer}
                  iconSize={'50%'}
                />
              }
              title="Total gross book sales"
              subtitle="Totals for books sold through the club and Folio"
            >
              <Grid container>
                <DashboardMetric
                  title="Total books sold"
                  rawValue={purchaseSumCount}
                  value={formatNumberReadable(purchaseSumCount)}
                />
                <DashboardMetric
                  title="Total net revenue"
                  value={`$${formatStringDollars(purchaseSumValue)}`}
                  variant="secondaryValue"
                />
              </Grid>
              <DashboardCardFooter
                linkText={exportCsvHtml}
                linkUrl="#"
                reportType={REPORT_TYPES.affiliate_book_sales}
                reportTitle="Total Gross Book Sales Report"
                reportMessagePending={REPORT_MESSAGES.pending}
                reportMessageComplete={REPORT_MESSAGES.complete}
              />
            </DashboardCard>
          </Grid>
        </Grid>
      </Grid>
    </StyledDefaultTemplate>
  );
}
