import { ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import DynamicThemeProvider from './components/organisms/DynamicThemeProvider';
import { AuthProvider } from './hooks/useAuth';
import client from './state/apolloClient';
import theme from './theme';

const root = ReactDOM.createRoot(document.querySelector('#root'));

root.render(
  <LocalizationProvider dateAdapter={DateFnsUtils}>
    <DynamicThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ApolloProvider>
    </DynamicThemeProvider>
  </LocalizationProvider>
);
