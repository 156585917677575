import Container from '@mui/material/Container';
import React from 'react';

const PREFIX = 'DefaultTemplate';

const classes = {
  container: `${PREFIX}-container`,
};

export default function Default(props) {
  return (
    <Container
      className={classes.container}
      sx={{
        p: { xs: 2, sm: 0 },
        mb: { xs: 1, sm: 1 },
      }}
      {...props}
    />
  );
}
