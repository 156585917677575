import { useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik } from 'formik';
import * as R from 'ramda';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import * as Yup from 'yup';

import ErrorBox from '../../components/atoms/ErrorBox';
import Link from '../../components/atoms/Link';
import Loading from '../../components/atoms/Loading';
import FormError from '../../components/atoms/form/FormError';
import FormRow from '../../components/atoms/form/FormRow';
import RadioGroup from '../../components/atoms/form/RadioGroup';
import DateTimePicker from '../../components/molecules/form/DateTimePicker';
import { SubmitButton } from '../../components/molecules/form/SubmitButton';
import TextField from '../../components/molecules/form/TextField';
import SnackBarContext from '../../components/organisms/SnackBar';
import { useAuth } from '../../hooks/useAuth';
import BookListMutation from '../../state/modules/book_list/BookListMutation';
import BookListQuery from '../../state/modules/book_list/BookListQuery';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { MEDIA_TYPES } from '../../utils/constants';
import { parseDateTime } from '../../utils/date';
import onSubmitHelper from '../../utils/onSubmitHelper';

const BookListSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  media_type: Yup.string().required(),
  feature_at: Yup.date().required('Required'),
  editorial_ordering: Yup.number().required(
    'Required: Set to -1 for default ordering'
  ),
  book_ids: Yup.string().required('Required'),
});

const modelReader = R.compose(
  R.applySpec({
    id: R.prop('id'),
    name: R.prop('name'),
    description: R.prop('description'),
    media_type: R.prop('media_type'),
    feature_at: R.compose(parseDateTime, R.prop('feature_at')),
    editorial_ordering: R.prop('editorial_ordering'),
    book_ids: R.compose(
      R.join('\n'),
      R.map(R.path(['book', 'isbn'])),
      R.prop('entries')
    ),
  }),
  R.prop('book_list')
);

const modelWriter = R.evolve({
  book_ids: R.compose(R.split('\n'), R.trim),
});

export default function BookListDetail() {
  const auth = useAuth();
  const { id } = useParams();
  const { loading, error, data } = useQuery(BookListQuery, {
    variables: { id },
  });
  const [mutate] = useMutation(BookListMutation);
  const { createSnack } = useContext(SnackBarContext);

  const formatMutationInput = (values) => {
    const model = modelWriter(values);
    // editorial_ordering must be a number
    model.editorial_ordering = parseInt(model.editorial_ordering);

    return model;
  };

  if (error) {
    return <ErrorBox error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <DefaultTemplate>
      <Box sx={{ mt: 3, mb: 5 }}>
        <Link color="inherit" to="/book-lists">
          Book Lists
        </Link>
        <Typography component="span"> &gt; {id}</Typography>
      </Box>
      <Formik
        initialValues={modelReader(data)}
        validationSchema={BookListSchema}
        onSubmit={onSubmitHelper((values) =>
          mutate({ variables: { input: formatMutationInput(values) } }).then(
            ({
              data: {
                book_list: { result, errors },
              },
            }) => {
              if (errors && errors.length) {
                throw new Error(errors[0]['messages'][0]);
              }
              createSnack('Book list saved!');
            }
          )
        )}
      >
        {({ isSubmitting, status, values, setFieldValue }) => (
          <Form>
            <FormRow>
              <TextField name="name" type="text" label="Name" />
            </FormRow>
            <FormRow>
              <TextField
                name="description"
                type="description"
                label="Description"
              />
            </FormRow>
            <FormRow>
              <Field
                component={DateTimePicker}
                clearable={true}
                label="Feature at"
                name="feature_at"
              />
            </FormRow>
            {auth.isStaff && (
              <>
                {/* always isStaff */}
                <FormRow>
                  <TextField
                    name="editorial_ordering"
                    label="Editorial Ordering"
                  />
                </FormRow>

                {/* isStaff until vertical expansion launch */}
                <FormRow>
                  <RadioGroup
                    name="media_type"
                    title="Media Type"
                    options={MEDIA_TYPES}
                    value={values.media_type}
                    setFieldValue={setFieldValue}
                  />
                </FormRow>
              </>
            )}
            <FormRow sx={{ pr: '50%' }}>
              <TextField
                multiline
                rows={16}
                name="book_ids"
                type="text"
                label="Books"
                helperText="Book ISBNs, one per line"
              />
            </FormRow>
            <FormRow>
              <FormError error={status} />
            </FormRow>
            <FormRow>
              <SubmitButton loading={isSubmitting}>Save</SubmitButton>
            </FormRow>
          </Form>
        )}
      </Formik>
    </DefaultTemplate>
  );
}
