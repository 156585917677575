import { gql } from '@apollo/client';

export default gql`
  fragment ContentFilterItem on ContentFilterItem {
    message
    club_book {
      club {
        title
      }
      book {
        title
        isbn
      }
    }
    updated_at
    user {
      uuid
      display_name
      pic
    }
  }
`;
