import { gql } from '@apollo/client';

import PageInfoFragment from '../PageInfoFragment';
import BookListFragment from './BookListFragment';

export default gql`
  query ($q: String, $after: String) {
    all_book_lists(q: $q, after: $after) {
      edges {
        node {
          ...BookList
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${BookListFragment}
  ${PageInfoFragment}
`;
