import { gql } from '@apollo/client';

import UserFragment from './UserFragment';

export default gql`
  query {
    current_user {
      ...User
    }
  }
  ${UserFragment}
`;
