import { gql } from '@apollo/client';

import ErrorFragment from '../ErrorFragment';
import AffiliateReportFragment from './AffiliateReportFragment';

export default gql`
  mutation ($input: ReportMutationInput!) {
    report(input: $input) {
      result {
        ...AffiliateReport
      }
      errors {
        ...Errors
      }
    }
  }
  ${AffiliateReportFragment}
  ${ErrorFragment}
`;
