import { Dialog as MuiDialog } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Dialog({
  title,
  message,
  extraActions,
  open,
  onClose,
  ...props
}) {
  return (
    <MuiDialog open={open} onClose={onClose} {...props}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {extraActions}
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}
