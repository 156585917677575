export function formatStringDollars(str) {
  return `${str.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

export function formatNumberReadable(num, numDecimals = 1) {
  const si = [
    { value: 1, suffix: '' },
    { value: 1e3, suffix: 'k' },
    { value: 1e6, suffix: 'M' },
  ];
  const regEx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (
    (num / si[i].value).toFixed(numDecimals).replace(regEx, '$1') + si[i].suffix
  );
}
