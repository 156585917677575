import BallotIcon from '@mui/icons-material/Ballot';
import IconDashboard from '@mui/icons-material/Dashboard';
import IconFeaturedPlayList from '@mui/icons-material/FeaturedPlayList';
import IconFilter from '@mui/icons-material/FilterAlt';
import IconHelp from '@mui/icons-material/Help';
import IconLogin from '@mui/icons-material/Login';
import IconMenu from '@mui/icons-material/Menu';
import IconBook from '@mui/icons-material/MenuBook';
import IconPayments from '@mui/icons-material/Payments';
import IconPerson from '@mui/icons-material/Person';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import Space from '../../components/atoms/Space';
import { useAuth } from '../../hooks/useAuth';
import {
  AFFILIATE_ADMIN_PAGES,
  COMPANY_ADMIN_PAGES,
  FONT_FAMILIES,
  LAYOUT,
  STAFF_PAGES,
  URLS,
} from '../../utils/constants';
import Avatar from '../atoms/Avatar';
import Head from '../atoms/Head';
import Link from '../atoms/Link';
import Logo from '../atoms/Logo';

const PREFIX = 'ResponsiveDrawer';

const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  appBar: `${PREFIX}-appBar`,
  content: `${PREFIX}-content`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerLink: `${PREFIX}-drawerLink`,
  logoutLink: `${PREFIX}-logoutLink`,
  menuButton: `${PREFIX}-menuButton`,
  toolbar: `${PREFIX}-toolbar`,
};

const IconButtonCustom = styled(IconButton)(({ theme }) => ({
  [`&.${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    minHeight: `${LAYOUT.drawerHeight}px`,
  },

  [`& .${classes.active}`]: {
    color: theme.palette.fable.green,
  },

  // necessary for content to be below app bar
  [`& .${classes.toolbar}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
    height: `${LAYOUT.drawerHeight}px`,
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const title = useMemo(() => {
    const path = location.pathname.replace(/^\/|\/$/g, '').split('/');
    if (path.length > 0) {
      const pages = {
        ...AFFILIATE_ADMIN_PAGES,
        ...COMPANY_ADMIN_PAGES,
        ...STAFF_PAGES,
        404: 'Page Not Found',
        signin: 'Sign In',
        'not-allowed': 'Not Allowed',
      };
      return pages[path[0]];
    }
  }, [location]);
  const auth = useAuth();

  // Closes drawer when window is resized back to tablet/desktop
  useEffect(() => {
    global.window.onresize = () => {
      const breakpoint = theme.breakpoints.values.sm;
      const windowWidth = global.window.innerWidth;

      if (windowWidth >= breakpoint && mobileOpen) {
        setMobileOpen(false);
      }
    };

    return () => {
      global.window.onresize = null;
    };
  }, [mobileOpen, theme.breakpoints.values.sm]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const Branding = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.fable.greenDarker,
  }));

  const Main = styled('main')(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    paddingTop: `${LAYOUT.drawerHeight}px`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: LAYOUT.drawerWidth,
      width: `calc(100% - ${LAYOUT.drawerWidth}px)`,
      '& > div': {
        borderLeft: `1px solid ${theme.palette.divider}`,
        minHeight: '100vh',
        padding: '15px 16px',
        '& > div.MuiContainer-root': {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  }));

  const Nav = styled('nav')(({ theme }) => ({
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      width: `${LAYOUT.drawerWidth}px`,
    },
  }));

  const UserName = styled('div')(({ theme }) => ({
    fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
    fontSize: '20px',
    letterSpacing: '0.15px',
    paddingTop: '10px',
  }));

  const TeamName = styled('div')(({ theme }) => ({
    color: theme.palette.fable.grayHurricane,
    letterSpacing: '0.25px',
    position: 'relative',
    fontFamily: FONT_FAMILIES.sf_ui_display_regular,
    fontSize: '14px',
    top: '-2px',
  }));

  const brandingWithLogo = (
    <Branding className={classes.toolbar}>
      <Logo
        left="16px"
        position="relative"
        width={118}
        variant={theme.palette.mode === 'dark' ? 'light' : 'dark'}
      />
    </Branding>
  );

  const StaffList = () => {
    return (
      <>
        <Link
          variant="nav"
          className={classes.drawerLink}
          to="books"
          color="inherit"
        >
          <ListItem button>
            <ListItemIcon>
              <IconBook />
            </ListItemIcon>
            <ListItemText primary="Books" />
          </ListItem>
        </Link>
        <Link
          variant="nav"
          className={classes.drawerLink}
          to="clubs"
          color="inherit"
        >
          <ListItem button>
            <ListItemIcon>
              <BallotIcon />
            </ListItemIcon>
            <ListItemText primary="Club Lists" />
          </ListItem>
        </Link>
        <Link
          variant="nav"
          className={classes.drawerLink}
          to="book-lists"
          color="inherit"
        >
          <ListItem button>
            <ListItemIcon>
              <IconFeaturedPlayList />
            </ListItemIcon>
            <ListItemText primary="Book Lists" />
          </ListItem>
        </Link>
        <Link
          variant="nav"
          className={classes.drawerLink}
          to="users"
          color="inherit"
        >
          <ListItem button>
            <ListItemIcon>
              <IconPerson />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </Link>
      </>
    );
  };

  const AffiliateAdminList = () => {
    return (
      <>
        <Link
          variant="nav"
          className={classes.drawerLink}
          to="payout"
          color="inherit"
        >
          <ListItem button>
            <ListItemIcon>
              <IconPayments />
            </ListItemIcon>
            <ListItemText primary="Payout" />
          </ListItem>
        </Link>
      </>
    );
  };

  const CompanyAdminList = () => {
    return (
      <>
        <Link
          variant="nav"
          className={classes.drawerLink}
          to="engagement"
          color="inherit"
        >
          <ListItem>
            <ListItemIcon>
              <IconDashboard />
            </ListItemIcon>
            <ListItemText primary="Engagement" />
          </ListItem>
        </Link>
        <Link
          variant="nav"
          className={classes.drawerLink}
          to="/engagement/content-filter"
          color="inherit"
        >
          <ListItem>
            <ListItemIcon>
              <IconFilter />
            </ListItemIcon>
            <ListItemText primary="Content Filter" />
          </ListItem>
        </Link>
      </>
    );
  };

  const DrawerCustom = styled(Drawer)(({ theme }) => ({
    [`&.${classes.drawerPaper}`]: {
      backgroundColor: theme.palette.fable.whiteFang,
      '& > div': {
        width: `${LAYOUT.drawerWidth}px`,
        borderRight: 'none',
      },
      // Needs to be here instead of under "root" for open mobile drawer
      '& .MuiTypography-root': {
        fontSize: '0.9rem',
        fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
      },
      '& div.userInfo': {
        padding: '32px 16px 11px',
      },
      '& .MuiAvatar-root': {
        backgroundColor: theme.palette.fable.grayMedium,
      },
      '& .userName': {},
      '& .teamName': {},
      '& .MuiDivider-root': {
        borderColor: theme.palette.fable.graySmoke,
        opacity: 1,
      },
      '& .MuiList-root': {
        paddingTop: '6px',
      },
      '& .MuiListItem-root': {
        padding: '15px 16px',
      },
      '& .MuiListItemIcon-root': {
        color: 'inherit',
        minWidth: '56px',
      },
      '& .MuiListItemSvg-root': {
        height: '1.1em',
        width: '1.1em',
      },
      '& .MuiListItemText-root': {
        letterSpacing: '0.1px',
      },
    },
  }));

  const container =
    window !== undefined ? () => window().document.body : undefined;

  function handleLogOut(e) {
    e.preventDefault();
    auth.setLoggedOut();
  }

  const RenderDrawer = (props) => (
    <DrawerCustom {...props} className={classes.drawerPaper}>
      <Root className={classes.drawerInner}>
        {brandingWithLogo}
        {auth.authUser !== null && (
          <>
            <div className="userInfo">
              <Avatar
                size="40px"
                src={auth.currentUser && URLS.cdn_base + auth.currentUser.pic}
              />
              <UserName>
                {R.pathOr(<Space />, ['display_name'], auth.currentUser)}
              </UserName>
              <TeamName>
                {R.pathOr(null, ['company', 'name'], auth.currentUser)}
              </TeamName>
            </div>
            <Divider />
          </>
        )}
        <List>
          {auth.isCompanyAdmin && !auth.isAffiliate && <CompanyAdminList />}
          {auth.isAffiliate && <AffiliateAdminList />}
          {auth.isStaff && <StaffList />}
          {auth.authUser === null && (
            <Link variant="nav" to="signin" color="inherit">
              <ListItem>
                <ListItemIcon>
                  <IconLogin />
                </ListItemIcon>
                <ListItemText primary="Sign In" />
              </ListItem>
            </Link>
          )}
          <Link
            variant="external"
            href={URLS.help}
            target="_blank"
            color="inherit"
          >
            <ListItem>
              <ListItemIcon>
                <IconHelp />
              </ListItemIcon>
              <ListItemText primary="Help &amp; Support" />
            </ListItem>
          </Link>
        </List>
      </Root>
    </DrawerCustom>
  );

  return (
    <div className={classes.root}>
      <Head title={title} />
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <IconButtonCustom
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <IconMenu />
          </IconButtonCustom>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          {auth.authUser !== null && location.pathname !== '/signin' && (
            <Link to="#" color="inherit" onClick={handleLogOut}>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.fable.grayMedium,
                  fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
                  fontSize: '14px',
                  lineHeight: '21px',
                  textAlign: 'right',
                  letterSpacing: '0.1px',
                  pr: theme.spacing(1),
                })}
              >
                Log out
              </Typography>
            </Link>
          )}
        </Toolbar>
      </AppBar>
      <Nav>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <RenderDrawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <RenderDrawer variant="permanent" open />
        </Box>
      </Nav>
      <Main>{props.children}</Main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
