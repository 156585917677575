import { cx } from '@emotion/css';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';

import { FONT_FAMILIES } from '../../../utils/constants';

const PREFIX = 'DashboardCard';

const classes = {
  card: `${PREFIX}-card`,
  cardTitle: `${PREFIX}-cardTitle`,
  cardSubtitle: `${PREFIX}-cardSubtitle`,
  largeCentered: `${PREFIX}-largeCentered`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.card}`]: {
    marginBottom: '24px',
    boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2)`,
    padding: theme.spacing(3),
    position: 'relative',
  },

  [`& .${classes.cardTitle}`]: {
    color: theme.palette.fable.grayUntitled1,
    fontSize: '14px',
    fontFamily: FONT_FAMILIES.sf_pro_text_semibold,
    letterSpacing: '-0.035em',
    lineHeight: '16px',
  },

  [`& .${classes.cardSubtitle}`]: {
    color: theme.palette.fable.grayUntitled1,
    fontSize: '11px',
    letterSpacing: '0.2px',
    lineHeight: '14px',
    maxWidth: '170px',
    paddingTop: theme.spacing(0.5),
  },

  [`& .${classes.largeCentered}`]: {},
}));

export default function DashboardCard(props) {
  return (
    <StyledCard className={cx(classes.card, props.className)}>
      <div className={props.variant}>
        {props.icon}
        <Typography component="div" className={classes.cardTitle}>
          {props.title}
        </Typography>
        <Typography component="div" className={classes.cardSubtitle}>
          {props.subtitle}
        </Typography>
        {props.children}
      </div>
    </StyledCard>
  );
}
