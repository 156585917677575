import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

export function formatDateTime(dt) {
  return dt ? format(dt, 'MMM d, yyyy h:mm a') : dt;
}

export function formatDate(dt) {
  return dt ? format(dt, 'MMM d, yyyy') : dt;
}

export function formatDateParam(dt, formatString = 'yyyy-MM-dd') {
  return dt ? format(dt, formatString) : dt;
}

export function parseDateTime(dt) {
  return dt ? parseISO(dt) : dt;
}

export function nowUtc() {
  const localNow = new Date();
  return addMinutes(localNow, localNow.getTimezoneOffset());
}
