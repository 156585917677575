import { gql } from '@apollo/client';

import ErrorFragment from '../ErrorFragment';
import BookListFragment from './BookListFragment';

export default gql`
  mutation ($input: BookListMutationInput!) {
    book_list(input: $input) {
      result {
        ...BookList
      }
      errors {
        ...Errors
      }
    }
  }
  ${BookListFragment}
  ${ErrorFragment}
`;
