import { gql } from '@apollo/client';

import PageInfoFragment from '../PageInfoFragment';
import ContentFilterItemFragement from './ContentFilterItemFragement';

export default gql`
  query ($company_id: String!, $q: String, $after: String) {
    content_filter_list(company_id: $company_id, q: $q, after: $after) {
      edges {
        node {
          ...ContentFilterItem
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ContentFilterItemFragement}
  ${PageInfoFragment}
`;
