import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';

const PREFIX = 'DashboardInfo';

const classes = {
  info: `${PREFIX}-info`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.info}`]: {
    height: '40px',
    boxSizing: 'border-box',
    background: theme.palette.fable.whiteAlmost,
    borderRadius: '4px',
    padding: '7px',
    '& span': {
      color: theme.palette.fable.grayHurricane,
      fontSize: '11px',
      letterSpacing: '0.25px',
      lineHeight: '14px',
    },
  },
}));

export default function DashboardInfo(props) {
  return (
    <StyledBox
      alignItems="center"
      className={classes.info}
      display="flex"
      mt={2}
    >
      {props.icon}
      <Typography
        className={classes.cardTitle}
        component="div"
        display="inherit"
        flexGrow={1}
        fontSize="inherit"
        lineHeight="inherit"
      >
        {props.message}
      </Typography>
    </StyledBox>
  );
}
