import { useEffect } from 'react';
import React from 'react';
import { Navigate } from 'react-router';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';

import Loading from '../components/atoms/Loading';
import Drawer from '../components/organisms/Drawer';
import { SnackProvider } from '../components/organisms/SnackBar';
import { useAuth } from '../hooks/useAuth';
import NotAllowedPage from './NotAllowed';
import NotFoundPage from './NotFoundPage';
import SignInPage from './SignInPage';
import BookListRouter from './book-lists/BookListRouter';
import BookRouter from './books/BookRouter';
import ClubRouter from './club/ClubRouter';
import DashboardRouter from './dashboards/DashboardRouter';
import PayoutRouter from './payout/PayoutRouter';
import UserRouter from './users/UserRouter';

const NON_CONTENT_PAGES = new Set(['', 'not-allowed', 'signin']);

function Root(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    const normalizedPath = location.pathname.replace(/^\/|\/$/g, '');

    // Where are we?
    const pathSegments = normalizedPath.split('/');

    // Where can we be?
    const allowedRoutes = auth.getAllowedRoutes();

    let navRoute;
    if (auth.currentUser === null) {
      if (pathSegments[0] !== 'signin') {
        navRoute = 'signin';
      }
    } else if (!allowedRoutes.length) {
      navRoute = 'not-allowed';
    } else if (
      pathSegments.length === 1 &&
      NON_CONTENT_PAGES.has(pathSegments[0])
    ) {
      navRoute = allowedRoutes[0];
    }

    // Go be there, if need be.
    if (navRoute && navRoute !== pathSegments[0]) {
      navigate(navRoute, location.search);
    }
  }, [props.defaultRoute, auth, location.pathname, location.search, navigate]);

  return (
    <Drawer>
      <SnackProvider>
        <Outlet />
      </SnackProvider>
    </Drawer>
  );
}

export default function RootRouter() {
  const auth = useAuth();

  if (auth.loading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route path="/" element={<Root defaultRoute={auth.defaultRoute} />}>
        {auth.isCompanyAdmin && (
          <Route path="engagement/*" element={<DashboardRouter />} />
        )}
        {auth.isCompanyAdmin && (
          <>
            <Route path="engagement/*" element={<DashboardRouter />} />
            <Route path="payout/*" element={<PayoutRouter />} />
          </>
        )}
        {auth.isStaff && <Route path="books/*" element={<BookRouter />} />}
        {auth.isStaff && (
          <Route path="book-lists/*" element={<BookListRouter />} />
        )}
        {auth.isStaff && <Route path="clubs/*" element={<ClubRouter />} />}
        {auth.isStaff && <Route path="users/*" element={<UserRouter />} />}
        <Route path="signin" element={<SignInPage />} />
        <Route path="not-allowed" element={<NotAllowedPage />} />
        <Route path="404" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to={auth.defaultRoute} />} />
      </Route>
    </Routes>
  );
}
