import * as R from 'ramda';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export default function Filter({
  children,
  name,
  defaultValue,
  valueMapper,
  onChangeMapper,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  valueMapper = valueMapper || R.identity;
  onChangeMapper = onChangeMapper || R.identity;
  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          name,
          value: valueMapper(searchParams.get(name) || defaultValue),
          onChange: (event) => {
            const newValue = event?.target?.value ?? event;
            searchParams.set(name, onChangeMapper(newValue));
            setSearchParams(searchParams, { replace: true });
          },
        });
      })}
    </>
  );
}
