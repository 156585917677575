import { useEffect } from 'react';
import { useState } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';

export default function useAutomaticRefetch(refetch, searchVariables) {
  // Hack: relayStylePagination seems to stop useQuery() from automatically re-fetching when variables change.
  // So we manually refetch here.
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useDeepCompareEffect(() => {
    if (!isInitialLoad) {
      refetch(searchVariables);
    }
  }, [searchVariables, isInitialLoad]);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  // End of hack
}
