import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import clsx from 'clsx';
import * as R from 'ramda';
import { useEffect } from 'react';
import { useState } from 'react';

import Link from '../../../components/atoms/Link';
import Dialog from '../../../components/organisms/Dialog';
import { useAuth } from '../../../hooks/useAuth';
import useSearchVariables from '../../../hooks/useSearchVariables';
import AffiliateReportMutation from '../../../state/modules/affiliate/AffiliateReportMutation';
import AffiliateReportQuery from '../../../state/modules/affiliate/AffiliateReportQuery';
import { FONT_FAMILIES } from '../../../utils/constants';

const PREFIX = 'DashboardCardFooter';

const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
  footer: `${PREFIX}-footer`,
  dialog: `${PREFIX}-dialog`,
  download: `${PREFIX}-download`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.divider}`]: {
    borderColor: theme.palette.fable.graySmoke,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
    marginBottom: '17px',
    opacity: 1,
  },

  [`& .${classes.footer}`]: {
    marginBottom: '-6px',
    '& a': {
      color: theme.palette.fable.green,
      fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
      fontSize: '14px',
      letterSpacing: '1px',
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
  },

  [`& .${classes.dialog}`]: {
    '& .MuiDialogTitle-root': {
      color: theme.palette.fable.grayStrong,
    },
    '& .MuiDialogContentText-root': {
      display: 'flex',
    },
    '& .MuiButton-label': {
      color: theme.palette.fable.grayStrong,
      fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
      letterSpacing: '1px',
    },
  },

  [`& .${classes.download}`]: {
    '&.Mui-disabled .MuiButton-label': {
      color: theme.palette.fable.shamrockLight,
    },
    '& .MuiButton-label': {
      color: theme.palette.fable.shamrock,
    },
  },
}));

export default function DashboardCardFooter(props) {
  const { linkText } = props;
  const { linkUrl } = props;
  const { reportType } = props;
  const { reportTitle } = props;
  const { reportMessagePending } = props;
  const { reportMessageComplete } = props;
  const { className } = props;

  const auth = useAuth();
  const theme = useTheme();

  const searchVariables = useSearchVariables();
  const [isReportPending, setReportPending] = useState(true);
  const [reportId, setReportId] = useState(null);
  const [reportFile, setReportFile] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [mutate] = useMutation(AffiliateReportMutation);

  const dialogContent = (
    <Root>
      {isReportPending && (
        <Box component="span" sx={{ width: 400, minHeight: 30 }}>
          <CircularProgress
            size={20}
            style={{ color: theme.palette.fable.shamrock }}
          />
          <Box component="span" ml={2} sx={{ lineHeight: '20px' }}>
            {reportMessagePending}
          </Box>
        </Box>
      )}
      {!isReportPending && (
        <Box
          component="span"
          sx={{
            width: 400,
            minHeight: 30,
            lineHeight: '20px',
          }}
        >
          {reportMessageComplete}
        </Box>
      )}
    </Root>
  );

  const dialogActions = (
    <Button
      href={reportFile}
      className={classes.download}
      disabled={isReportPending}
      onClick={() => {
        setOpen(false);
      }}
    >
      Download Now
    </Button>
  );

  const { data, stopPolling } = useQuery(AffiliateReportQuery, {
    variables: { id: reportId },
    pollInterval: 1000,
    fetchPolicy: 'network-only',
    skip: !reportId,
  });

  const handleReport = (report) => {
    const id = R.pathOr(null, ['id'], report);
    const status = R.pathOr(null, ['status'], report);
    const file = R.pathOr(null, ['file'], report);

    if (status === 'pending') {
      setReportId(id);
    } else if (status === 'complete' && file !== 'null') {
      setReportFile(file);
      setReportPending(false);
    }
  };

  const pollForPendingReport = (report_type, company_id, start, end) => {
    setReportId(null);
    setReportFile(null);
    setReportPending(true);

    mutate({
      variables: {
        input: {
          report_type,
          parameters: { company_id },
          start,
          end,
        },
      },
    }).then(
      ({
        data: {
          report: { result, errors },
        },
      }) => {
        if (errors && errors.length) {
          setReportId(null);
          throw new Error(errors[0]['messages'][0]);
        } else {
          handleReport(result);
        }
      }
    );
  };

  useEffect(() => {
    if (data && isReportPending) {
      handleReport(R.pathOr(null, ['report'], data));
    } else if (!isReportPending) {
      return stopPolling();
    }
  }, [data, isReportPending, stopPolling]);

  return (
    <>
      <Box className={clsx(classes.footer, className)}>
        <Divider className={classes.divider} />
        <Link
          variant="external"
          href={linkUrl || ''}
          color="inherit"
          no-hover-underline
          onClick={(evt) => {
            evt.preventDefault();
            pollForPendingReport(
              reportType,
              R.pathOr(null, ['company', 'id'], auth.currentUser),
              searchVariables.start,
              searchVariables.end
            );
            setOpen(true);
          }}
        >
          {linkText}
        </Link>
      </Box>
      <Dialog
        className={classes.dialog}
        title={reportTitle}
        message={dialogContent}
        extraActions={dialogActions}
        onClose={() => {
          setOpen(false);
          setReportId(null);
          setReportFile(null);
          setReportPending(true);
        }}
        open={isOpen}
      />
    </>
  );
}
