import { gql } from '@apollo/client';

export default gql`
  fragment ClubItem on ClubItem {
    id
    title
    creator {
      uuid
      display_name
      pic
    }
    active_members
  }
`;
