import { gql } from '@apollo/client';

import AccountFragment from './AccountFragment';

export default gql`
  query {
    payout_account {
      ...Account
      onboarding_link {
        url
      }
    }
  }
  ${AccountFragment}
`;
