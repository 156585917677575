import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import * as R from 'ramda';
import React from 'react';

import Link from '../../components/atoms/Link';
import FilterBar from '../../components/molecules/filter/FilterBar';
import ResetFilter from '../../components/molecules/filter/ResetFilter';
import TextFilter from '../../components/molecules/filter/TextFilter';
import QueryTable from '../../components/organisms/QueryTable';
import AllUsersQuery from '../../state/modules/user/AllUsersQuery';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { formatDate, parseDateTime } from '../../utils/date';

const PREFIX = 'UserIndex';

const classes = {
  search: `${PREFIX}-search`,
};

const StyledDefaultTemplate = styled(DefaultTemplate)(({ theme }) => ({
  // @TODO: Switch to "sx" when MUI enables responsive Grid spacing
  // https://github.com/mui-org/material-ui/issues/6140#issuecomment-802787556
  [`& .${classes.search}`]: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
    },
  },
}));

const queryNode = 'all_users';

const cols = [
  {
    getter: (row) => (
      <Link color="secondary.main" to={row.id} title={row.id}>
        {R.takeLast(7, row.id)}
      </Link>
    ),
    name: 'ID',
    flex: 2,
  },
  { getter: R.prop('email'), name: 'Email', flex: 2 },
  { getter: R.prop('display_name'), name: 'Name', flex: 3 },
  {
    getter: R.compose(formatDate, R.prop('date_joined')),
    name: 'Date joined',
    flex: 1,
  },
  {
    getter: R.compose(formatDate, R.prop('last_login')),
    name: 'Last login',
    flex: 1,
  },
];

const mapRows = R.compose(
  R.map(
    R.compose(
      R.over(R.lensProp('last_login'), parseDateTime),
      R.over(R.lensProp('date_joined'), parseDateTime)
    )
  )
);

export default function UserIndex() {
  return (
    <StyledDefaultTemplate>
      <FilterBar>
        <Grid container alignItems="center" justify="left">
          <Grid item xs={12} sm={6} className={classes.search}>
            <TextFilter name="q" label="Search" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ResetFilter />
          </Grid>
        </Grid>
      </FilterBar>
      <QueryTable
        cols={cols}
        mapRows={mapRows}
        queryNode={queryNode}
        query={AllUsersQuery}
      />
    </StyledDefaultTemplate>
  );
}
