import TextField from '@mui/material/TextField';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers-pro';
import React from 'react';

export default function DateTimePicker({
  field: { onChange: _onChange, ...field },
  form: { touched, errors, setFieldValue },
  ...props
}) {
  const onChange = (value) => {
    setFieldValue(field.name, value);
  };
  const fieldError = touched[field.name] && errors[field.name];
  return (
    <MuiDateTimePicker
      renderInput={(inputProps) => (
        <TextField
          {...inputProps}
          error={Boolean(fieldError)}
          helperText={fieldError}
          variant="outlined"
        />
      )}
      onChange={onChange}
      {...field}
      {...props}
    />
  );
}
