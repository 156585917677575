import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import React from 'react';

import ErrorBox from '../../components/atoms/ErrorBox';
import Loading from '../../components/atoms/Loading';
import AccountLoginQuery from '../../state/modules/connect/AccountLoginQuery';
import PayoutLogin from './PayoutLogin';
import PayoutOnboarding from './PayoutOnboarding';

export default function PayoutIndex() {
  return (
    <Box
      sx={{
        mt: 5,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <PayoutIndexMain />
    </Box>
  );
}
function PayoutIndexMain() {
  const { loading, error, data } = useQuery(AccountLoginQuery, {
    fetchPolicy: 'network-only',
  });

  if (error) {
    return <ErrorBox error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (data?.payout_account?.details_submitted) {
    return <PayoutLogin account={data.payout_account} />;
  }

  return <PayoutOnboarding />;
}
