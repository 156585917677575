import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { createContext } from 'react';
import { useState } from 'react';
import React from 'react';

const SnackBarContext = createContext();

function RenderSnack({ id, message, open, handleClose, options }) {
  const messageId = `message-${id}`;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': messageId,
      }}
    >
      <SnackbarContent
        style={options.contentStyle}
        message={<span id={messageId}>{message}</span>}
      />
    </Snackbar>
  );
}

let uniqueId = 2;

export const SnackProvider = ({ children }) => {
  const [{ current, queue }, setState] = useState({ current: null, queue: [] });

  function createSnack(message, options = {}) {
    const id = uniqueId++;
    const snack = { id, message, open: true, options };

    if (current) {
      setState({ current, queue: queue.concat(snack) });
    } else {
      setState({ queue, current: snack });
    }

    return id;
  }

  function handleClose() {
    setState((currentState) => ({
      ...currentState,
      current: { ...currentState.current, open: false },
    }));
    // time to snack close animation
    setTimeout(openNext, 1000);
  }

  function openNext() {
    if (queue.length) {
      setState({ current: queue[0], queue: queue.slice(1) });
    } else {
      setState({ current: null, queue: [] });
    }
  }

  return (
    <SnackBarContext.Provider value={{ createSnack }}>
      {current && (
        <RenderSnack key={current.id} {...current} handleClose={handleClose} />
      )}
      {children}
    </SnackBarContext.Provider>
  );
};

export default SnackBarContext;
