import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as RA from 'ramda-adjunct';
import React from 'react';

import { FONT_FAMILIES } from '../../../utils/constants';

const PREFIX = 'DashboardMetric';

const classes = {
  metric: `${PREFIX}-metric`,
  metricValue: `${PREFIX}-metricValue`,
  metricTitle: `${PREFIX}-metricTitle`,
  secondaryValue: `${PREFIX}-secondaryValue`,
  large: `${PREFIX}-large`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.metric}`]: {
    minWidth: '135px',
  },

  [`& .${classes.metricValue}`]: {
    color: theme.palette.fable.blackSwan,
    fontSize: '42px',
    fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
    letterSpacing: '-2px',
    lineHeight: '40px',
    marginTop: theme.spacing(3),
  },

  [`& .${classes.metricTitle}`]: {
    color: theme.palette.fable.blackSwan,
    fontSize: '11px',
    fontFamily: FONT_FAMILIES.sf_ui_display_medium,
    letterSpacing: '0.2px',
    lineHeight: '12px',
  },

  [`& .${classes.secondaryValue}`]: {
    fontSize: '20px',
    letterSpacing: '-1px',
    position: 'relative',
    top: '5px',
  },

  [`& .${classes.large}`]: {
    fontSize: '80px',
    lineHeight: '80px',
  },
}));

export default function DashboardMetric(props) {
  const { title } = props;
  const { value } = props;
  const { rawValue } = props;
  const { variant } = props;

  const variantClass = !RA.isUndefined(variant) ? classes[variant] : '';
  const conditionalTitle =
    !RA.isUndefined(rawValue) && rawValue > 999 ? `${rawValue} ${title}` : '';
  return (
    <StyledGrid item className={classes.metric}>
      <Tooltip
        title={conditionalTitle}
        arrow
        enterDelay={1000}
        placement="bottom-start"
      >
        <Typography
          component="div"
          className={`${classes.metricValue} ${variantClass}`}
        >
          {value}
        </Typography>
      </Tooltip>
      <Typography component="div" className={classes.metricTitle}>
        {title}
      </Typography>
    </StyledGrid>
  );
}
