import React from 'react';
import { Route, Routes } from 'react-router';

import PayoutIndex from './PayoutIndex';

export default function PayoutRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<PayoutIndex />} />
      </Routes>
    </div>
  );
}
