import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import * as R from 'ramda';
import React from 'react';

import FilterBar from '../../components/molecules/filter/FilterBar';
import ResetFilter from '../../components/molecules/filter/ResetFilter';
import TextFilter from '../../components/molecules/filter/TextFilter';
import QueryTable from '../../components/organisms/QueryTable';
import { useAuth } from '../../hooks/useAuth';
import ContentFilterQuery from '../../state/modules/content_filter/ContentFilterListQuery';
import DefaultTemplate from '../../templates/DefaultTemplate';

const PREFIX = 'ContentFilterList';

const classes = {
  search: `${PREFIX}-search`,
  publisher: `${PREFIX}-publisher`,
};

const StyledDefaultTemplate = styled(DefaultTemplate)(({ theme }) => ({
  // @TODO: Switch to "sx" when MUI enables responsive Grid spacing
  // https://github.com/mui-org/material-ui/issues/6140#issuecomment-802787556
  [`& .${classes.search}`]: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
      paddingRight: theme.spacing(2),
    },
  },

  [`& .${classes.publisher}`]: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
      paddingRight: theme.spacing(2),
    },
  },
}));

const queryNode = 'content_filter_list';

const cols = [
  {
    getter: R.pathOr('', ['club_book', 'club', 'title']),
    name: 'Club',
    flex: 2,
  },
  { getter: R.pathOr('', ['message']), name: 'Message', flex: 3 },
  {
    getter: R.prop('updated_at'),
    name: 'Updated At',
    flex: 2,
  },
  {
    getter: R.pathOr('', ['user', 'display_name']),
    name: 'Sender',
    flex: 1,
  },
];

export default function List() {
  const auth = useAuth();

  return (
    <StyledDefaultTemplate>
      <FilterBar>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={5} className={classes.search}>
            <TextFilter name="q" label="Search" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ResetFilter />
          </Grid>
        </Grid>
      </FilterBar>
      <QueryTable
        cols={cols}
        query={ContentFilterQuery}
        queryNode={queryNode}
        additionalQueryVariables={{
          company_id: R.pathOr(null, ['company', 'id'], auth.currentUser),
        }}
      />
    </StyledDefaultTemplate>
  );
}
