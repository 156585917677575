import { gql } from '@apollo/client';

import BookListFragment from './BookListFragment';

export default gql`
  query ($id: ID!) {
    book_list(id: $id) {
      ...BookList
    }
  }
  ${BookListFragment}
`;
