import { ApolloClient } from '@apollo/client';
import { createHttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { ApolloLink } from 'apollo-link';
import { fromPromise } from 'apollo-link';
import { onError } from 'apollo-link-error';
import firebase from 'firebase/app';
import * as R from 'ramda';
import { serializeError } from 'serialize-error';

const isInvalidJWTError = R.compose(
  R.ifElse(R.isNil, R.F, R.match(/JWT was found to be invalid/)),
  R.path(['result', 'detail'])
);

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (networkError) {
      if (networkError.statusCode === 403 && isInvalidJWTError(networkError)) {
        console.log(
          'Refreshing token and retrying operation',
          serializeError(networkError)
        );
        return fromPromise(firebase.auth().currentUser.getIdToken()).flatMap(
          (token) => {
            handleToken(token);
            return forward(operation);
          }
        );
      }
    }
  }
);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_BASE_URL,
});

let token = null;

export async function handleToken(t) {
  token = t;
}

const authLink = setContext((request, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        all_books: relayStylePagination(),
        all_book_lists: relayStylePagination(),
        all_users: relayStylePagination(),
      },
    },
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
});

export default client;
