import TextField from '@mui/material/TextField';
import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useState } from 'react';

export default function DebouncedTextField({
  value: valueProp,
  onChange: onChangeProp,
  delay = 500,
  ...props
}) {
  const [value, setValue] = useState(valueProp);
  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);
  const onChange = useMemo(() => {
    const debouncedOnChange = debounce((e) => onChangeProp(e), delay);
    return (e) => {
      setValue(e.target.value);
      debouncedOnChange(e);
    };
  }, [onChangeProp, delay]);
  return <TextField value={value} onChange={onChange} {...props} />;
}
