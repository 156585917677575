import { gql } from '@apollo/client';

import PageInfoFragment from '../PageInfoFragment';
import BookFragment from './BookFragment';

export default gql`
  query ($q: String, $source: String, $after: String) {
    all_books(q: $q, source: $source, after: $after) {
      edges {
        node {
          ...Book
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${BookFragment}
  ${PageInfoFragment}
`;
