import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDDTAUL4hpHMG95iHIh8yRb0-2irN0YOvs',
  authDomain: 'fable-backend.firebaseapp.com',
  databaseURL: 'https://fable-backend.firebaseio.com',
  projectId: 'fable-backend',
  storageBucket: 'fable-backend.appspot.com',
  messagingSenderId: '229629520960',
  appId: '1:229629520960:web:b3ab177e477d9a476083a5',
  measurementId: 'G-6KP5LSR4FV',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

if (process.env.NODE_ENV === 'development') {
  window.firebase = firebase;
}

const firbaseAuthEmulatorHost =
  process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST;
if (firbaseAuthEmulatorHost) {
  firebase.auth().useEmulator(`http://${firbaseAuthEmulatorHost}`);
}

export const authProviderRedirect = (path, key, history) => {
  if (path) {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.credential) {
          history.push(path);
          localStorage.removeItem(key);
        }
      })
      .catch((e) => {
        console.log('Auth provider sign-in error', e);
        localStorage.removeItem(key);
      });
  }
};

export default firebase;
