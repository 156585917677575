import { gql } from '@apollo/client';

export default gql`
  fragment AffiliateEventSummary on AffiliateEventSummary {
    source
    stats {
      sum_count
      sum_value
    }
  }
`;
