import React from 'react';
import ReactHelmet from 'react-helmet';

import { SITE_NAME } from '../../utils/constants';

export default function Head({ title }) {
  const formatTitle = (title) =>
    title ? `${title} - ${SITE_NAME}` : SITE_NAME;
  return (
    <ReactHelmet>
      <title>{formatTitle(title)}</title>
    </ReactHelmet>
  );
}
