import { gql } from '@apollo/client';

export default gql`
  fragment User on User {
    id
    email
    display_name
    company {
      id
      name
      agreement_type
      inactivity_timeout
    }
    company_roles {
      type
    }
    date_joined
    last_login
    is_staff
    is_active
    pic
    phone
    feature_flags {
      time_travel_date
    }
    stripe_url
  }
`;
