import { LicenseInfo } from '@mui/x-license-pro';
import * as R from 'ramda';
import { useEffect } from 'react';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyles from './GlobalStyles';
import history from './history';
import { useAuth } from './hooks/useAuth';
import RootRouter from './pages/RootRouter';
import { authProviderRedirect } from './services/firebase';
import { STORAGE_KEYS } from './utils/constants';

LicenseInfo.setLicenseKey(
  'c6311be34293c74f7956cf5917c87136Tz01ODA0MCxFPTE3MDU3MDQwOTkwNDQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

export default function App() {
  // redirect user on successful sign in with auth provider
  useEffect(() => {
    const redirectPath = localStorage.getItem(STORAGE_KEYS.auth_redirect_path);
    authProviderRedirect(
      redirectPath,
      STORAGE_KEYS.auth_redirect_path,
      history
    );
  }, []);
  const auth = useAuth();

  const isInactivityTimeout = R.pathOr(
    null,
    ['company', 'inactivity_timeout'],
    auth.currentUser
  );

  const { getLastActiveTime, resume, pause } = useIdleTimer({
    timeout: 15 * 1000 * 60,
    onIdle: (e) => {
      console.info('user is idle', e);
      console.info('last active', getLastActiveTime());
      auth.setLoggedOut();
      pause();
    },
    debounce: 500,
  });

  useEffect(() => {
    !isInactivityTimeout && pause();
    isInactivityTimeout && resume();
  }, [isInactivityTimeout, pause, resume]);

  return (
    <BrowserRouter>
      <GlobalStyles />
      <RootRouter />
    </BrowserRouter>
  );
}
