import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import * as R from 'ramda';
import React from 'react';

import A from '../../components/atoms/A';
import FilterBar from '../../components/molecules/filter/FilterBar';
import ResetFilter from '../../components/molecules/filter/ResetFilter';
import SelectFilter from '../../components/molecules/filter/SelectFilter';
import TextFilter from '../../components/molecules/filter/TextFilter';
import QueryTable from '../../components/organisms/QueryTable';
import AllBooksQuery from '../../state/modules/book/AllBooksQuery';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { PUBLISHER_OPTIONS, PUBLISHERS } from '../../utils/constants';

const PREFIX = 'BookIndex';

const classes = {
  search: `${PREFIX}-search`,
  publisher: `${PREFIX}-publisher`,
};

const StyledDefaultTemplate = styled(DefaultTemplate)(({ theme }) => ({
  // @TODO: Switch to "sx" when MUI enables responsive Grid spacing
  // https://github.com/mui-org/material-ui/issues/6140#issuecomment-802787556
  [`& .${classes.search}`]: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
      paddingRight: theme.spacing(2),
    },
  },

  [`& .${classes.publisher}`]: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
      paddingRight: theme.spacing(2),
    },
  },
}));

const queryNode = 'all_books';

const cols = [
  {
    getter: (row) => (
      <A
        color="secondary.main"
        href={`https://fable.co/store/book/${row.isbn}`}
        target="_blank"
      >
        {row.isbn}
      </A>
    ),
    name: 'ISBN',
    flex: 2,
  },
  { getter: R.prop('title'), name: 'Title', flex: 3 },
  {
    getter: R.compose(R.join(', '), R.map(R.prop('name')), R.prop('authors')),
    name: 'Authors',
    flex: 2,
  },
  {
    getter: R.prop('price_usd'),
    name: 'Price',
    flex: 1,
  },
  {
    getter: R.compose((source) => PUBLISHERS[source], R.prop('source')),
    name: 'Publisher',
    flex: 1,
  },
];

export default function BookIndex() {
  return (
    <StyledDefaultTemplate>
      <FilterBar>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={5} className={classes.search}>
            <TextFilter name="q" label="Search" />
          </Grid>
          <Grid item xs={12} sm={5} className={classes.publisher}>
            <SelectFilter
              name="source"
              options={[{ value: '', label: '' }, ...PUBLISHER_OPTIONS]}
              label="Publisher"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ResetFilter />
          </Grid>
        </Grid>
      </FilterBar>
      <QueryTable cols={cols} query={AllBooksQuery} queryNode={queryNode} />
    </StyledDefaultTemplate>
  );
}
