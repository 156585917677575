import { gql } from '@apollo/client';

import PageInfoFragment from '../PageInfoFragment';
import UserFragment from './UserFragment';

export default gql`
  query ($q: String, $after: String) {
    all_users(q: $q, after: $after) {
      edges {
        node {
          ...User
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${UserFragment}
  ${PageInfoFragment}
`;
