import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { useState } from 'react';

import A from '../../../components/atoms/A';
import TextField from './TextField';

const StyledTextField = styled(TextField)`
  position: relative;
`;

const StyledA = styled(A)`
  cursor: pointer;
  position: absolute;
  left: auto;
  top: 50%;
  right: 20px;
  bottom: auto;
  transform: translateY(-50%);
  text-decoration: underline;
  user-select: none;
  color: ${(props) => props.theme.palette.fable.grayHurricane};
  &:hover {
    color: ${(props) => props.theme.palette.fable.blackSwan};
  }
`;

export default function PasswordField({ ...props }) {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const type = isVisible ? 'text' : 'password';
  const toggleTitle = isVisible ? 'Hide' : 'Show';

  return (
    <Box sx={{ position: 'relative' }}>
      <StyledTextField {...props} type={type} />
      <StyledA onClick={toggleVisibility}>{toggleTitle}</StyledA>
    </Box>
  );
}
