import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

export default function DynamicThemeProvider({ theme: themeProp, children }) {
  const prefersDarkMode = false; //useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        ...themeProp,
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          ...themeProp.palette,
        },
      }),
    [prefersDarkMode, themeProp]
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
