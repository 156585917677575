import React from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router';
import { Navigate } from 'react-router';

import ClubEngagementDashboard from './ClubEnagementDashboard';
import ClubIndex from './ClubIndex';

export default function ClubRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<ClubIndex />} />
        <Route path=":id" element={<ClubEngagementDashboard />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </div>
  );
}
