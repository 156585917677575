import { gql } from '@apollo/client';

import PageInfoFragment from '../PageInfoFragment';
import ClubItemFragement from './ClubItemFragement';

export default gql`
  query ($company_id: String, $q: String, $after: String) {
    club_list(company_id: $company_id, q: $q, after: $after) {
      edges {
        node {
          ...ClubItem
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ClubItemFragement}
  ${PageInfoFragment}
`;
