import { gql } from '@apollo/client';

export default gql`
  fragment Book on Book {
    id
    isbn
    title
    authors {
      name
      slug
    }
    price_usd
    source
  }
`;
