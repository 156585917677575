import { Global } from '@emotion/react';
import { css } from '@emotion/react';

import NoeDisplayRegularWoff from './fonts/noedisplay-400-regular.woff';
import NoeDisplayRegularWoff2 from './fonts/noedisplay-400-regular.woff2';
import NoeDisplayBoldWoff from './fonts/noedisplay-700-bold.woff';
import NoeDisplayBoldWoff2 from './fonts/noedisplay-700-bold.woff2';
import SfProTextLightWoff from './fonts/sfprotext-300-light.woff';
import SfProTextLightWoff2 from './fonts/sfprotext-300-light.woff2';
import SfProTextRegularWoff from './fonts/sfprotext-400-regular.woff';
import SfProTextRegularWoff2 from './fonts/sfprotext-400-regular.woff2';
import SfProTextMediumWoff from './fonts/sfprotext-500-medium.woff';
import SfProTextMediumWoff2 from './fonts/sfprotext-500-medium.woff2';
import SfProTextSemiboldWoff from './fonts/sfprotext-600-semibold.woff';
import SfProTextSemiboldWoff2 from './fonts/sfprotext-600-semibold.woff2';
import SfProTextBoldWoff from './fonts/sfprotext-700-bold.woff';
import SfProTextBoldWoff2 from './fonts/sfprotext-700-bold.woff2';
import SfUiDisplayLightWoff from './fonts/sfuidisplay-300-light.woff';
import SfUiDisplayLightWoff2 from './fonts/sfuidisplay-300-light.woff2';
import SfUiDisplayRegularWoff from './fonts/sfuidisplay-400-regular.woff';
import SfUiDisplayRegularWoff2 from './fonts/sfuidisplay-400-regular.woff2';
import SfUiDisplayMediumWoff from './fonts/sfuidisplay-500-medium.woff';
import SfUiDisplayMediumWoff2 from './fonts/sfuidisplay-500-medium.woff2';
import SfUiDisplaySemiboldWoff from './fonts/sfuidisplay-600-semibold.woff';
import SfUiDisplaySemiboldWoff2 from './fonts/sfuidisplay-600-semibold.woff2';
import SfUiDisplayBoldWoff from './fonts/sfuidisplay-700-bold.woff';
import SfUiDisplayBoldWoff2 from './fonts/sfuidisplay-700-bold.woff2';
import { defaultFontStack } from './theme';
import { FONT_FAMILIES } from './utils/constants';

// Noe Display
const NoeDisplayRegularFontFace = getFontFace(
  FONT_FAMILIES.noe_display_regular,
  NoeDisplayRegularWoff,
  NoeDisplayRegularWoff2
);
const NoeDisplayBoldFontFace = getFontFace(
  FONT_FAMILIES.noe_display_bold,
  NoeDisplayBoldWoff,
  NoeDisplayBoldWoff2
);

// SF UI Display
const SfUiDisplayLightFontFace = getFontFace(
  FONT_FAMILIES.sf_ui_display_light,
  SfUiDisplayLightWoff,
  SfUiDisplayLightWoff2
);
export const SfUiDisplayRegularFontFace = getFontFace(
  FONT_FAMILIES.sf_ui_display_regular,
  SfUiDisplayRegularWoff,
  SfUiDisplayRegularWoff2
);
const SfUiDisplayMediumFontFace = getFontFace(
  FONT_FAMILIES.sf_ui_display_medium,
  SfUiDisplayMediumWoff,
  SfUiDisplayMediumWoff2
);
const SfUiDisplaySemiboldFontFace = getFontFace(
  FONT_FAMILIES.sf_ui_display_semibold,
  SfUiDisplaySemiboldWoff,
  SfUiDisplaySemiboldWoff2
);
const SfUiDisplayBoldFontFace = getFontFace(
  FONT_FAMILIES.sf_ui_display_bold,
  SfUiDisplayBoldWoff,
  SfUiDisplayBoldWoff2
);

// SF Pro Text
const SfProTextLightFontFace = getFontFace(
  FONT_FAMILIES.sf_pro_text_light,
  SfProTextLightWoff,
  SfProTextLightWoff2
);
const SfProTextRegularFontFace = getFontFace(
  FONT_FAMILIES.sf_pro_text_regular,
  SfProTextRegularWoff,
  SfProTextRegularWoff2
);
const SfProTextMediumFontFace = getFontFace(
  FONT_FAMILIES.sf_pro_text_medium,
  SfProTextMediumWoff,
  SfProTextMediumWoff2
);
const SfProTextSemiboldFontFace = getFontFace(
  FONT_FAMILIES.sf_pro_text_semibold,
  SfProTextSemiboldWoff,
  SfProTextSemiboldWoff2
);
const SfProTextBoldFontFace = getFontFace(
  FONT_FAMILIES.sf_pro_text_bold,
  SfProTextBoldWoff,
  SfProTextBoldWoff2
);

function getFontFace(family, woff, woff2) {
  return {
    '@font-face': {
      fontFamily: `"${family}"`,
      src: `url("${woff2}") format("woff2"), url("${woff}") format("woff")`,
      // Using "font-weight: normal;" avoids browser-based typeface synthesis
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
  };
}

function GlobalStyles() {
  const styles = [
    css(NoeDisplayRegularFontFace).styles,
    css(NoeDisplayBoldFontFace).styles,

    css(SfUiDisplayLightFontFace).styles,
    css(SfUiDisplayRegularFontFace).styles,
    css(SfUiDisplayMediumFontFace).styles,
    css(SfUiDisplaySemiboldFontFace).styles,
    css(SfUiDisplayBoldFontFace).styles,

    css(SfProTextLightFontFace).styles,
    css(SfProTextRegularFontFace).styles,
    css(SfProTextMediumFontFace).styles,
    css(SfProTextSemiboldFontFace).styles,
    css(SfProTextBoldFontFace).styles,

    css({
      /*
      '.MuiTypography-root.MuiTypography-body1': {
        fontFamily: defaultFontStack,
      },
      */
      body: {
        margin: 0,
      },
      '.MuiTypography-body1': {
        fontFamily: defaultFontStack,
      },
    }).styles,
  ];

  const globalCss = css`
    ${styles.join('\n')}
  `;

  return <Global styles={globalCss} />;
}

export default GlobalStyles;
