import blue from '@mui/material/colors/blue';
import pink from '@mui/material/colors/pink';

import { COLORS, LAYOUT } from './utils/constants';
import { FONT_FAMILIES } from './utils/constants';

export const defaultFontStack = [FONT_FAMILIES.sf_ui_display_regular].join(',');

const theme = {
  palette: {
    background: {
      default: COLORS.whiteAlmost,
    },
    fable: {
      ...COLORS,
    },
    primary: blue,
    secondary: pink,
    text: {
      primary: COLORS.blackSwan,
    },
    error: {
      main: COLORS.mahogany,
      contrastText: COLORS.whiteFang,
    },
  },
  typography: {
    fontFamily: defaultFontStack,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: COLORS.green,
          boxShadow: 'none',
          flexGrow: 1,
          minHeight: `${LAYOUT.drawerHeight}px`,
          [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${LAYOUT.drawerWidth}px)`,
            marginLeft: LAYOUT.drawerWidth,
          },
        }),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.mixins.toolbar,
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'start',
          minHeight: `${LAYOUT.drawerHeight}px !important`,
          '& h6': {
            color: theme.palette.fable.whiteFang,
            flexGrow: 1,
            fontFamily: FONT_FAMILIES.sf_ui_display_semibold,
            fontSize: '1.5rem',
            letterSpacing: 'normal',
            paddingLeft: '6px',
            paddingTop: '3px',
          },
        }),
      },
    },
  },
  props: {
    MuiModal: {
      disableEnforceFocus: true,
      disableAutoFocus: true,
      disableRestoreFocus: true,
    },
    MuiDialog: {
      disableEnforceFocus: true,
      disableAutoFocus: true,
      disableRestoreFocus: true,
    },
    MuiPopper: {
      disablePortal: true,
    },
  },
  overrides: {
    MuiPopper: {
      disablePortal: true,
    },
  },
};

export default theme;
