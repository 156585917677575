import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function NotAllowedPage() {
  return (
    <Container maxWidth="sm">
      <Typography variant="h2" align="center" gutterBottom>
        Not allowed
      </Typography>
      <Typography variant="body" component="p" align="center" gutterBottom>
        This site is reserved for Fable staff and company admins.
      </Typography>
    </Container>
  );
}
