import React from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router';

import BookListDetail from './BookListDetail';
import BookListIndex from './BookListIndex';

export default function BookListRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<BookListIndex />} />
        <Route path=":id" element={<BookListDetail />} />
      </Routes>
    </div>
  );
}
