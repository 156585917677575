import { gql } from '@apollo/client';

export default gql`
  fragment CompanyEngagementSummary on CompanyEngagementSummary {
    reacting_unique_count
    commenting_unique_count
    highlighting_unique_count
    reading_unique_count
    unique_engaged_count
    unique_join_club_count
    total_user_count
    discussion_prompt_message_count
    highlight_message_count
    regular_message_count
    total_message_count
  }
`;
