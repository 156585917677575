import { styled, alpha, darken, lighten } from '@mui/material/styles';
import clsx from 'clsx';
import * as R from 'ramda';
import React from 'react';

const PREFIX = 'Table';

const classes = {
  root: `${PREFIX}-root`,
  head: `${PREFIX}-head`,
  body: `${PREFIX}-body`,
};

const Root = styled('section')(({ theme }) => ({
  /* Styles applied to the root element. */
  [`& .${classes.root}`]: {
    ...theme.typography.body2,
    // Workaround for a rendering bug with spanned columns in Chrome 62.0.
    // Removes the alpha (sets it to 1), and lightens or darkens the theme color.
    borderBottom: `1px solid
    ${
      theme.palette.mode === 'light'
        ? lighten(alpha(theme.palette.divider, 1), 0.88)
        : darken(alpha(theme.palette.divider, 1), 0.68)
    }`,
    textAlign: 'left',
    padding: 16,
    /* Fable custom styles */
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  /* Styles applied to the root element if `variant="head"` or `context.table.head`. */
  [`& .${classes.head}`]: {
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightMedium,
  },

  /* Styles applied to the root element if `variant="body"` or `context.table.body`. */
  [`& .${classes.body}`]: {
    color: theme.palette.text.primary,
  },
}));

const Cell = ({ className, ...props }) => (
  <div className={clsx(classes.root, classes.body, className)} {...props} />
);

const HeaderCell = ({ className, ...props }) => (
  <div className={clsx(classes.root, classes.head, className)} {...props} />
);

const Row = styled('div')({
  display: 'flex',
});

const transformCols = R.map(R.evolve({ flex: R.defaultTo(1) }));

export default function Table({ rows, cols: inCols, ...props }) {
  const cols = transformCols(inCols);
  return (
    <Root {...props}>
      <Row>
        {cols.map((col, i) => (
          <HeaderCell key={i} style={{ flex: col.flex }}>
            {col.name}
          </HeaderCell>
        ))}
      </Row>
      {rows.map((row, i) => (
        <Row key={i}>
          {cols.map((col, j) => (
            <Cell key={j} style={{ flex: col.flex }}>
              {col.getter(row)}
            </Cell>
          ))}
        </Row>
      ))}
    </Root>
  );
}
