import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import * as R from 'ramda';
import React from 'react';

import Link from '../../components/atoms/Link';
import FilterBar from '../../components/molecules/filter/FilterBar';
import ResetFilter from '../../components/molecules/filter/ResetFilter';
import TextFilter from '../../components/molecules/filter/TextFilter';
import QueryTable from '../../components/organisms/QueryTable';
import ClubListQuery from '../../state/modules/club/ClubListQuery';
import DefaultTemplate from '../../templates/DefaultTemplate';

const PREFIX = 'ClubIndex';

const classes = {
  search: `${PREFIX}-search`,
  publisher: `${PREFIX}-publisher`,
};

const StyledDefaultTemplate = styled(DefaultTemplate)(({ theme }) => ({
  // @TODO: Switch to "sx" when MUI enables responsive Grid spacing
  // https://github.com/mui-org/material-ui/issues/6140#issuecomment-802787556
  [`& .${classes.search}`]: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
      paddingRight: theme.spacing(2),
    },
  },

  [`& .${classes.publisher}`]: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
      paddingRight: theme.spacing(2),
    },
  },
}));

const queryNode = 'club_list';

const cols = [
  {
    getter: (row) => (
      <Link color="secondary.main" to={row.id} title={row.id}>
        {row.title}
      </Link>
    ),
    name: 'Club',
    flex: 3,
  },
  {
    getter: R.pathOr('', ['creator', 'display_name']),
    name: 'Owner',
    flex: 1,
  },
  {
    getter: R.prop('active_members'),
    name: 'Active Members',
    flex: 2,
  },
];

export default function ClubIndex() {
  return (
    <StyledDefaultTemplate>
      <FilterBar>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={5} className={classes.search}>
            <TextFilter name="q" label="Search" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ResetFilter />
          </Grid>
        </Grid>
      </FilterBar>
      <QueryTable cols={cols} query={ClubListQuery} queryNode={queryNode} />
    </StyledDefaultTemplate>
  );
}
