import { gql } from '@apollo/client';

import UserFragment from './UserFragment';

export default gql`
  query ($id: ID!) {
    user(id: $id) {
      ...User
    }
  }
  ${UserFragment}
`;
