import { gql } from '@apollo/client';

import AffiliateSummaryFragment from './AffiliateSummaryFragment';

export default gql`
  query ($company_id: String!, $start: Date!, $end: Date!) {
    affiliate_summary(company_id: $company_id, start: $start, end: $end) {
      ...AffiliateSummary
    }
  }
  ${AffiliateSummaryFragment}
`;
