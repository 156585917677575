import { gql } from '@apollo/client';

import ClubEngagementSummary from './ClubEngagementSummaryFragment';

export default gql`
  query ($club_id: String!, $start: Date!, $end: Date!) {
    club_engagement_summary(club_id: $club_id, start: $start, end: $end) {
      ...ClubEngagementSummary
    }
  }
  ${ClubEngagementSummary}
`;
