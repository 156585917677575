import { addDays } from 'date-fns';

import { nowUtc } from './date';

const now = nowUtc();

export const SITE_NAME = 'Fable Console';

export const LAYOUT = {
  pageMaxWidth: 840,
  drawerHeight: 60,
  drawerWidth: 256,
};

export const URLS = {
  homepage: 'https://fable.co',
  cdn_base: 'https://cdn.fable.co/',
  help: 'https://help.fable.co',
  terms: 'https://fable.co/terms',
  privacy: 'https://fable.co/privacy',
};

export const COLORS = {
  blackSwan: '#161015',
  denim: '#116ecd',
  denimLight: '#b5cbe2',
  grayHurricane: '#847f7c',
  grayMedium: '#d1cfd0',
  grayUntitled1: '#6C666B',
  graySmoke: '#ededed',
  grayStrong: '#2e272d',
  green: '#064c37',
  greenDarker: '#053829',
  mahogany: '#cb3f3f',
  pinkMoon: '#e4adbb',
  shamrock: '#0a8e4f',
  shamrockLight: '#9ae2bf',
  tussockLight: '#dfcda7',
  whiteAlmost: '#f9f9f9',
  whiteFang: '#ffffff',
};

export const PUBLISHERS = {
  simonandschuster: 'Simon and Schuster',
  harpercollins: 'HarperCollins',
  macmillan: 'Macmillan',
  penguinrandomhouse: 'PenguinRandomHouse',
  hachette: 'Hachette',
  coresource: 'CoreSource',
  standardebooks: 'Standard Ebooks',
};

export const PUBLISHER_OPTIONS = Object.keys(PUBLISHERS).map((k) => ({
  value: k,
  label: PUBLISHERS[k],
}));

export const COMPANY_AGREEMENT_TYPES = Object.fromEntries(
  ['affiliate', 'service'].map((key) => [key, key])
);

export const DEFAULT_ROUTES = {
  company_admin: 'engagement',
  anonymous: 'signin',
  staff: 'books',
};

export const STAFF_PAGES = {
  books: 'Books',
  book: 'Book',
  clubs: 'Clubs',
  'book-lists': 'Book Lists',
  overview: 'Overview',
  users: 'Users',
};

export const AFFILIATE_ADMIN_PAGES = {
  payout: 'Payout',
};

export const COMPANY_ADMIN_PAGES = {
  engagement: 'Engagement',
};

export const FORM_ERRORS = {
  invalid_email: 'Please enter a valid email address.',
  required_email: 'Please enter an email address.',
  required_email_and_password: 'Please enter an email and password.',
  required_password: 'Please enter a password.',
};

export const FORM_PLACEHOLDERS = {
  email: 'Enter an email address',
  password: 'Enter a password',
};

export const FONT_FAMILIES = {
  noe_display_regular: 'Noe Display Regular',
  noe_display_bold: 'Noe Display Bold',
  sf_ui_display_light: 'SF UI Display Light',
  sf_ui_display_regular: 'SF UI Display Regular',
  sf_ui_display_medium: 'SF UI Display Medium',
  sf_ui_display_semibold: 'SF UI Display Semibold',
  sf_ui_display_bold: 'SF UI Display Bold',
  sf_pro_text_light: 'SFProText Light',
  sf_pro_text_regular: 'SFProText Regular',
  sf_pro_text_medium: 'SFProText Medium',
  sf_pro_text_semibold: 'SFProText Semibold',
  sf_pro_text_bold: 'SFProText Bold',
};

export const STORAGE_KEYS = {
  auth_redirect_path: 'auth_redirect_path',
};

export const DATES = {
  today: now,
  reporting_min: 0,
  reporting_max: now,
  reporting_start: addDays(now, -30),
  reporting_end: now,
};

export const REPORT_TYPES = Object.fromEntries(
  [
    'affiliate_book_sales',
    'affiliate_club_member',
    'affiliate_page_view',
    'affiliate_subscription',
  ].map((key) => [key, key])
);

export const REPORT_MESSAGES = {
  pending: 'Please wait while your report is being generated...',
  complete: 'Your report is now ready.',
};

export const MEDIA_TYPES = [
  { value: 'watch', label: 'TV Shows', name: 'watch' },
  { value: 'book', label: 'Books', name: 'book' },
];
