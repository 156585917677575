import React from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router';

import UserDetail from './UserDetail';
import UserIndex from './UserIndex';

export default function UserRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<UserIndex />} />
        <Route path=":id" element={<UserDetail />} />
      </Routes>
    </div>
  );
}
