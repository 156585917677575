import Button from '@mui/material/Button';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export default function ResetFilter() {
  const [_, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  return (
    <Button
      onClick={() =>
        setSearchParams(new URLSearchParams(''), { replace: true })
      }
    >
      Reset Filters
    </Button>
  );
}
