import { gql } from '@apollo/client';

import BookFragment from '../book/BookFragment';

export default gql`
  fragment BookList on BookList {
    id
    name
    description
    media_type
    feature_at
    editorial_ordering
    entries {
      id
      book {
        ...Book
      }
      sort_value
    }
  }
  ${BookFragment}
`;
