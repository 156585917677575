import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

import Button from '../../atoms/form/Button';

function Spinner(props) {
  return <CircularProgress size={20} {...props} />;
}

const StyledSpinner = styled(Spinner)`
  margin-left: 8px;
`;

export function SubmitButton({ children, loading, ...rest }) {
  return (
    <Button type="submit" {...rest}>
      {children}
      {loading && <StyledSpinner />}
    </Button>
  );
}
