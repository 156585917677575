import React from 'react';

import DebouncedTextField from '../form/DebouncedTextField';
import Filter from './Filter';

export default function TextFilter({ name, ...props }) {
  return (
    <Filter name={name} defaultValue="">
      <DebouncedTextField type="search" {...props} />
    </Filter>
  );
}
