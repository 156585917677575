import { useMutation, useQuery } from '@apollo/client';
import { TableBody } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik } from 'formik';
import * as R from 'ramda';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import * as Yup from 'yup';

import A from '../../components/atoms/A';
import ErrorBox from '../../components/atoms/ErrorBox';
import Link from '../../components/atoms/Link';
import Loading from '../../components/atoms/Loading';
import FormError from '../../components/atoms/form/FormError';
import FormRow from '../../components/atoms/form/FormRow';
import DateTimePicker from '../../components/molecules/form/DateTimePicker';
import { SubmitButton } from '../../components/molecules/form/SubmitButton';
import SnackBarContext from '../../components/organisms/SnackBar';
import UserMutation from '../../state/modules/user/UserMutation';
import UserQuery from '../../state/modules/user/UserQuery';
import { formatDate, parseDateTime } from '../../utils/date';
import onSubmitHelper from '../../utils/onSubmitHelper';

const queryNode = 'user';

const readModel = R.compose(
  R.over(R.lensProp('last_login'), parseDateTime),
  R.over(R.lensProp('date_joined'), parseDateTime),
  R.prop(queryNode)
);

const UserSchema = Yup.object().shape({
  feature_flags: Yup.object().shape({
    time_travel_date: Yup.date().nullable().default(null),
  }),
});

const writeModel = R.applySpec({
  id: R.prop('id'),
  feature_flags: R.compose(
    R.pick(['time_travel_date']),
    R.prop('feature_flags')
  ),
});

export default function UserDetail() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(UserQuery, {
    variables: { id },
  });

  const [mutate] = useMutation(UserMutation);
  const { createSnack } = useContext(SnackBarContext);

  if (error) {
    return <ErrorBox error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const model = readModel(data);

  return (
    <>
      <Box sx={{ mt: 3, mb: 5 }}>
        <Link color="inherit" to="/users">
          Users
        </Link>
        <Typography variant="body"> &gt; {id}</Typography>
      </Box>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th">Email</TableCell>
            <TableCell>{model.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Display name</TableCell>
            <TableCell>{model.display_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Last login</TableCell>
            <TableCell>{formatDate(model.last_login)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Date joined</TableCell>
            <TableCell>{formatDate(model.date_joined)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Links</TableCell>
            <TableCell>
              <ul>
                <li>
                  <A
                    color="secondary.main"
                    href={`https://app.revenuecat.com/customers/af5c7b40/${model.id}`}
                    target="_blank"
                  >
                    RevenueCat
                  </A>
                </li>
                {model.stripe_url && (
                  <li>
                    <A
                      color="secondary.main"
                      href={model.stripe_url}
                      target="_blank"
                    >
                      Stripe
                    </A>
                  </li>
                )}
                <li>
                  <A
                    color="secondary.main"
                    href={`https://fly.customer.io/env/99952/people/${model.id}`}
                    target="_blank"
                  >
                    Customer.io
                  </A>
                </li>
                <li>
                  <A
                    color="secondary.main"
                    href={`https://mixpanel.com/report/2236252/view/2783796/profile#distinct_id=${model.id}`}
                    target="_blank"
                  >
                    Mixpanel
                  </A>
                </li>
              </ul>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box mt={8} mb={4}>
        <Typography variant="title">Feature flags</Typography>
      </Box>
      <Formik
        initialValues={model}
        validationSchema={UserSchema}
        onSubmit={onSubmitHelper((values) =>
          mutate({ variables: { input: writeModel(values) } }).then(
            ({
              data: {
                user: { result, errors },
              },
            }) => {
              if (errors && errors.length) {
                throw new Error(errors[0]['messages'][0]);
              }
              createSnack('User saved!');
            }
          )
        )}
      >
        {({ isSubmitting, status }) => (
          <Form>
            <FormRow>
              <Field
                label="Time travel date"
                component={DateTimePicker}
                clearable={true}
                name="feature_flags.time_travel_date"
              />
            </FormRow>
            <FormRow>
              <FormError error={status} />
            </FormRow>
            <FormRow>
              <SubmitButton loading={isSubmitting}>Save</SubmitButton>
            </FormRow>
          </Form>
        )}
      </Formik>
    </>
  );
}
